import { useQuery } from '@tanstack/react-query';

// Define the API base URL
const URBAN_API_BASE = 'https://iot-rest-prod.urban.io/api/v1';

// Define the response structure for type safety
interface Location {
  id: string;
  name: string;
  external_id: string;
}

// Load environment variables for authentication
const headerUser: string = process.env.REACT_APP_X_USER_EMAIL!;
const headerUserToken: string = process.env.REACT_APP_X_USER_TOKEN!;

// Fetch function using fetch (No operatorId required)
const fetchLocations = async (): Promise<Location[]> => {
  const response = await fetch(`${URBAN_API_BASE}/list_locations`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-User-Email': headerUser,
      'X-User-Token': headerUserToken,
    },
  });

  if (!response.ok) {
    throw new Error(`Error fetching locations: ${response.statusText}`);
  }

  return response.json();
};

// React Query Hook (No operatorId needed)
export const useListAllTrains = () => {
  return useQuery<Location[], Error>({
    queryKey: ['locations'],
    queryFn: fetchLocations, // Calls fetchLocations directly
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    retry: 2, // Retry failed requests twice
  });
};
