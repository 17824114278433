import {Box, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import {Order, tableHeaderObj} from "./tableUtils.ts";
import {useTheme} from "@mui/styles";
import {MouseEventHandler} from "react";
import {styled} from '@mui/material/styles';

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
    '&:hover': {
        color: theme.palette.success.main,
    },
    '&.Mui-active': {
        color: theme.palette.text.destructive,
    },

    '&.Mui-active .MuiTableSortLabel-icon': {
        color: theme.palette.text.destructive,
    },

    '& .MuiTableSortLabel-icon': {
        color: theme.palette.success.main,
    },
}));

interface SharedTableHeaderProps {
    order: Order;
    orderBy: string;
    showEventTypeHeader?: boolean;
    createSortHandler: (property: string) => MouseEventHandler<HTMLAnchorElement> | undefined | MouseEventHandler<HTMLSpanElement>;
}

export function SharedTableHeader({order, orderBy, showEventTypeHeader = false, createSortHandler}: SharedTableHeaderProps) {
    const theme = useTheme();

    return (
        <TableHead>
            <TableRow sx={{
                'td, th': {color: theme.palette.text.primary},
                '& > *': {
                    borderBottom: (theme) =>
                        open ? 'unset' : `2px solid ${theme.palette.border.greyLight}`,
                },
            }}>
                <TableCell>
                    <StyledTableSortLabel
                        active={orderBy === tableHeaderObj.restriction.key}
                        direction={orderBy === tableHeaderObj.restriction.key ? order : 'asc'}
                        onClick={createSortHandler(tableHeaderObj.restriction.key)}
                    >
                        {tableHeaderObj.restriction.text}
                        {orderBy === tableHeaderObj.restriction.key ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </StyledTableSortLabel>
                </TableCell>
                <TableCell>
                    <StyledTableSortLabel
                        sx={{
                            color: theme.palette.text.primary
                        }}
                        active={orderBy === tableHeaderObj.place.key}
                        direction={orderBy === tableHeaderObj.place.key ? order : 'asc'}
                        onClick={createSortHandler(tableHeaderObj.place.key)}
                    >
                        {tableHeaderObj.place.text}
                        {orderBy === tableHeaderObj.place.key ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </StyledTableSortLabel>
                </TableCell>
                <TableCell>Bereörda växlar</TableCell>
                <TableCell>Hastighet</TableCell>
                <TableCell>Orsak</TableCell>
                {showEventTypeHeader && <TableCell>Åtgärdstyp</TableCell>}
                <TableCell>
                    <StyledTableSortLabel
                        sx={{
                            color: theme.palette.text.primary
                        }}
                        active={orderBy === tableHeaderObj.startTimestamp.key}
                        direction={orderBy === tableHeaderObj.startTimestamp.key ? order : 'asc'}
                        onClick={createSortHandler(tableHeaderObj.startTimestamp.key)}
                    >
                        {tableHeaderObj.startTimestamp.text}
                        {orderBy === tableHeaderObj.startTimestamp.key ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </StyledTableSortLabel>
                </TableCell>
                <TableCell>
                    <StyledTableSortLabel
                        sx={{
                            color: theme.palette.text.primary
                        }}
                        active={orderBy === tableHeaderObj.endTimestamp.key}
                        direction={orderBy === tableHeaderObj.endTimestamp.key ? order : 'asc'}
                        onClick={createSortHandler(tableHeaderObj.endTimestamp.key)}
                    >
                        {tableHeaderObj.endTimestamp.text}
                        {orderBy === tableHeaderObj.endTimestamp.key ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </StyledTableSortLabel>
                </TableCell>
                <TableCell>Detaljer</TableCell>
                <TableCell> </TableCell>
            </TableRow>
        </TableHead>
    );
}
