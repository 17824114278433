import {callAPI} from 'pages/trafficsim/api/net/api.ts';
import {lazy} from 'react';

const Debug_data_19_06_2024 = lazy(
  () =>
    process.env.REACT_FEATURE_TRACK_SIM_DEBUG &&
    import(
      'pages/trafficsim/config/gothenburg/debug_data_snapshots/debug_data_19_06_2024.js'
    ),
);

export const addRestriction = async (data) => {
  try {
    const path = '/api/v1/restriction/';
    return await callAPI(path, 'POST', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const removeRestriction = async (restrictionID, payload) => {
  try {
    const path = `/api/v1/restriction/${restrictionID}/remove`;
    return  await callAPI(path, 'DELETE', payload);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const getCurrentRestrictions = async (page=1, pageSize=20) => {
  try {

    const path = `/api/v1/restriction/?restriction_status=current&page=${page}&pageSize=${pageSize}`;

    return await callAPI(path, 'GET', {});
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const getUpcomingRestrictions = async (page=1, pageSize=20) => {
  try {

    const path = `/api/v1/restriction/?restriction_status=upcoming&page=${page}&pageSize=${pageSize}`;

    return await callAPI(path, 'GET', {});
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const getMapData = async (useDebugData = false) => {
  if (useDebugData) return Debug_data_19_06_2024;
  try {
    const path = `/api/v1/map/`;
    return await callAPI(path, 'GET', {});
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const updateRemoteRestriction = async (data) => {
  try {
    const path = `/api/v1/restriction/`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const fetchRemoteRestrictionHistory = async (page=1, pageSize=20) => {
  try {
    const path = `/api/v1/restriction/logs?page=${page}&pageSize=${pageSize}`;
    return await callAPI(path, 'GET', null);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const searchRemoteRestrictionHistory = async (data) => {
  try {
    const path = `/api/v1/restriction/logs?start_timestamp=${data.startTimestamp}&end_timestamp=${data.endTimestamp}&page=${data.page}&pageSize=${data.pageSize}`;
    return await callAPI(path, 'GET', null);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const batchUpdateRemoteMapObjects = async (data) => {
  try {
    const path = `/api/v1/map/batch/update`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};
