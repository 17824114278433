import React from 'react';
import {Grid, Stack, SvgIcon, Typography} from '@mui/material';
import {CemitAppProfileItem} from 'components/apps/cemitAppComponents/CemitAppProfileItem.tsx';
import SWRLogo from 'assets/images/sandbox/SWR-logo.svg';
import TranspotiseLogo from 'assets/images/sandbox/transportise-logo.png';
import TXMLOGO from 'assets/images/sandbox/transportise-log.png';
import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';

const SandboxHeaderBar = ({
  appProps,
  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  return (
    <Stack
      {...{
        direction: 'row',
        sx: {
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
          width: '100%',
          minWidth: '100%',
        },
      }}
    >
      <Stack
        {...{
          direction: 'row',
          sx: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: 2,
          },
        }}
      >
        <SvgIcon
          component={SWRLogo}
          viewBox="0 0 250 70"
          sx={{width: '150px', height: 'auto'}}
        />

        <Typography variant="subtitle2" sx={{color:"white"}}>Powered By</Typography>

        <img
          src={TranspotiseLogo}
          width={'130px'}
          height={'100px'}
          alt="Transpotise Logo"
          
        />
      </Stack>

      <img
          src={TXMLOGO}
          width={'130px'}
          height={'100px'}
          alt="Transpotise Logo"
        />
      {organizationProps.isAdmin ? (
        <CemitAppProfileItem {...{appProps, organizationProps}} />
      ) : undefined}
    </Stack>
    // <Grid direction="row" item xs={5} sx={{mb: 1, width: '100%', minWidth: '100%'}}>
    //   <Grid direction="column" alignItems="center" spacing={2} sx={{width: '100%'}}>
    //     <Stack
    //       {...{
    //         direction: 'row',
    //         sx: {justifyContent: 'space-between', alignItems: 'center'},
    //       }}
    //     >
    //       <SvgIcon
    //         {...{
    //           component: SWRLogo,
    //           viewBox: '0 0 250 70',
    //           sx: {width: '150px', height: 'auto'},
    //         }}
    //       />
    //       <CemitAppProfileItem
    //         {...{
    //           appProps,
    //           organizationProps,
    //         }}
    //       />
    //     </Stack>
    //     <Stack {...{direction: 'column'}}>
    //       <Typography variant="subtitle2"> Powered By</Typography>
    //       <img
    //         src={TranspotiseLogo as unknown as string}
    //         width={'100px'}
    //         height={'100px'}
    //       />
    //     </Stack>
    //   </Grid>
    // </Grid>
  );
};

export default SandboxHeaderBar;
