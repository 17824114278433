import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { throwUnlessDefined } from 'utils/functional/functionalUtils.ts';

// Function to fetch a batch of event data from the backend
const fetchEventDataBatch = async (referenceIds: string[], page: number): Promise<any[]> => {
  if (referenceIds.length === 0) return [];

  const baseUrl = throwUnlessDefined(process.env.REACT_APP_DETECTOR_API_URL);
  const queryString = referenceIds.map((id) => `reference_ids=${encodeURIComponent(id)}`).join('&');
  const eventsApiUrl = `${baseUrl}?${queryString}&orderBy=-detection-time&page=${page}&pageSize=200`;

  const response = await fetch(eventsApiUrl, {
    method: 'GET',
    headers: { accept: 'application/json' },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch events data (Page ${page})`);
  }

  const data = await response.json();

  // Check if the response contains 'data' field and return it
  if (data?.data && Array.isArray(data.data)) {
    return data.data; // Return the actual array inside 'data'
  } else {
    console.error('Expected an array under the "data" field, but got:', data);
    return []; // Return an empty array if the expected structure is not found
  }
};

// Function to fetch all pages of event data before updating state
const fetchAllEventData = async (referenceIds: string[]): Promise<any[]> => {
  const maxBatchSize = 200;
  let allData: any[] = [];

  let currentPage = 1;
  let hasMoreData = true;

  while (hasMoreData) {
    const batch = referenceIds.slice((currentPage - 1) * maxBatchSize, currentPage * maxBatchSize);
    const batchData = await fetchEventDataBatch(batch, currentPage);
    allData = [...allData, ...batchData];

    // If batch size is smaller than maxBatchSize, we reached the last page
    if (batchData.length < maxBatchSize) {
      hasMoreData = false;
    } else {
      currentPage++;
    }
  }

  return allData;
};

// Custom hook to load `detectorsWithTags`, fetch event data, and merge before updating state
const useDetectorsWithTags = () => {
  const [detectorsWithTags, setDetectorsWithTags] = useState<any[]>([]);
  const [combinedData, setCombinedData] = useState<any[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  // Load detectorsWithTags from localStorage
  useEffect(() => {
    try {
      const storedData = localStorage.getItem('detectorsWithTags'); // Change to sessionStorage if needed
      if (storedData) {
        setDetectorsWithTags(JSON.parse(storedData));
      }
    } catch (error) {
      console.error('Failed to load detectorsWithTags:', error);
    }
  }, []);

  // Extract all decimalTagIDs
  const referenceIds = detectorsWithTags.flatMap((detector) =>
    detector.tags?.map((tag: any) => tag.decimalTagID) || []
  );

  // Fetch event data using React Query
  const { data: eventsData, isLoading, error } = useQuery({
    queryKey: ['fetchEvents', referenceIds],
    queryFn: () => fetchAllEventData(referenceIds),
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
    enabled: referenceIds.length > 0,
    staleTime: 5 * 60 * 1000, // Cache event data for 5 minutes
  });

  // Handle errors
  useEffect(() => {
    if (error) {
      setErrors((prevErrors) => [...prevErrors, (error as Error).message]);
    }
  }, [error]);

  // Merge all data only when everything is ready
  useEffect(() => {
    if (!isLoading && eventsData && detectorsWithTags.length > 0) {
      console.log("EventData", eventsData);

      // Append eventsData to the current combinedData
      setCombinedData((prevData) => [...prevData, ...eventsData]);
    }
  }, [eventsData, isLoading, detectorsWithTags]);

  return {
    data: combinedData,
    isLoading: isLoading,
    errors,
  };
};

export default useDetectorsWithTags;
