import { useMemo } from "react";
import { useQueries, useQueryClient } from "@tanstack/react-query";

// Load environment variables for authentication
const headerUser: string = process.env.REACT_APP_X_USER_EMAIL!;
const headerUserToken: string = process.env.REACT_APP_X_USER_TOKEN!;

const API_URL = "https://iot-rest-prod.urban.io/api/v1/list_datastreams";

// Helper function to generate random percentages
const getRandomPercentage = () => `${Math.floor(Math.random() * 100)}%`;

// Helper function to generate a random status
const getRandomStatus = () => {
  const statuses = ["On Time", "Delayed", "Cancelled", "Arriving Soon"];
  return statuses[Math.floor(Math.random() * statuses.length)];
};

// Helper function to generate a random estimated arrival time
const getRandomETA = () => {
  const now = new Date();
  now.setMinutes(now.getMinutes() + Math.floor(Math.random() * 60)); // ETA in the next 60 minutes
  return now.toISOString();
};

// Helper function to randomly select a station
const getRandomStation = () => {
  const stations = ["BEDHAMPTON", "HAVANT", "PORTSMOUTH", "SOUTHAMPTON", "LONDON"];
  return stations[Math.floor(Math.random() * stations.length)];
};

// Fetch sandboxes and extract device external IDs
const fetchSandboxes = async (external_id: string) => {
  if (!external_id) return "";

  const url = `${API_URL}?scope=location&scope_id=${external_id}&datastream_types=Process Loop (4-20mA)`;

  const response = await fetch(url, {
    headers: {
        Accept: 'application/json',
        'X-User-Email': headerUser,
        'X-User-Token': headerUserToken,
      },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch sandboxes for ${external_id}`);
  }

  const data = await response.json();

  // Extract external_ids from device objects and join as a comma-separated string
  return data.datastreams
  ?.map((datastream: any) => datastream?.external_id)
  .filter(Boolean) || []; // Ensure it's always an array
};

export const useTrainSandboxes = (locations: { external_id: string; name: string }[]) => {
  const queryClient = useQueryClient();

  // Convert locations into train structure
  const trains = useMemo(() => {
    return locations.map((location) => {
      const numMatch = location.name.match(/(450|444)\d+/);
      if (!numMatch) return null;

      const fullNumber = numMatch[0];
      const fleetNumber = fullNumber.substring(0, 3);

      return {
        status: getRandomStatus(),
        sandboxes: [],
        fleet: Number(fleetNumber),
        trainId: Number(fullNumber),
        sandboxIds: process.env.REACT_SANDBOX_EXTERNAL_KEY!.split(","), // Environment variable for sandbox IDs
        name: location.name,
        travellingFrom: getRandomStation(),
        travellingTo: getRandomStation(),
        estimatedArrival: getRandomETA(),
        currentPosition: `Next Station ${Math.floor(Math.random() * 5) + 1}`, // Random station number
        isSandingLocation: Math.random() < 0.5, // Random boolean
        headSandboxPairAvg: getRandomPercentage(),
        tailSandboxPairAvg: getRandomPercentage(),
        colorArray: ["#8884d8", "#82ca9d", "#ffc658", "#ff7300"],
        externalId: location.external_id,
      };
    }).filter(Boolean);
  }, [locations]);

  // Fetch sandbox data for each train
  const sandboxQueries = useQueries({
    queries: trains.map((train) => ({
      queryKey: ["sandboxes", train.externalId],
      queryFn: () => fetchSandboxes(train.externalId),
      staleTime: 1000 * 60 * 5, // Cache for 5 minutes
      refetchInterval: 300000, // Refetch every 30 seconds
      retry: 2, // Retry failed requests twice
    })),
  });

  // Function to manually refresh all sandbox data
  const refreshSandboxes = () => {
    trains.forEach((train) => {
      queryClient.invalidateQueries(["sandboxes", train.externalId]);
    });
  };

  // Combine train data with sandbox query results
  const trainsDataWithSandBoxIds = trains.map((train, index) => ({
    ...train,
    sandboxes: sandboxQueries[index].data || "",
    isLoading: sandboxQueries[index].isLoading,
    error: sandboxQueries[index].error,
  }));

  return { trainsDataWithSandBoxIds, refreshSandboxes };
};
