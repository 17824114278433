import React, {useState, useEffect} from 'react';
import {Typography, Divider, CardContent, Card, Chip, Stack, alpha} from '@mui/material';
import {Tram, Search} from '@mui/icons-material';
import {TagData, VehicleStatus} from '../../types/index.ts';
import {findTramInfoByNumber} from '../../utils/findTramInformation.tsx';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
interface SideBarTrainCardProps {
  processedTags: VehicleStatus[];
  functionName : string
  onSelectTag: (tram: TagData) => void;
  selectedTag: VehicleStatus | undefined;
}
import {DefaultTheme, useTheme,  } from '@mui/styles';
const allTramWithDate = new Map<string, string>();
const gettingAverageOfSignals = (value: number, count: number): number => {
  const result = (value / count) * 100;
  return parseFloat(result.toFixed(1));
};
const SideBarTrainCard: React.FC<SideBarTrainCardProps> = ({
  processedTags,
  onSelectTag,
  selectedTag,
  functionName
}) => {
  const [localSelectedTag, setLocalSelectedTag] = useState<VehicleStatus | undefined>(
    selectedTag,
  );
   const theme = useTheme(); 
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<VehicleStatus[]>([]);
  const [closeFocusTram, setCloseFocusTram] = useState<Boolean>(true);
  useEffect(() => {
    if (processedTags) {
      let updatedData = [...processedTags];
      processedTags.forEach((e) => {
        allTramWithDate.set(e.vehicleid, e.detectionTime);
      });
  
      if (localSelectedTag) {
        const updatedTag = processedTags.find(
          (tag) => tag.vehicleid === localSelectedTag.vehicleid
        );
        if (updatedTag) {
          setLocalSelectedTag(updatedTag); // Ensure we use the found tag instead
          // Remove the localSelectedTag from the list if it's already there
          updatedData = updatedData.filter(
            (tag) => tag.vehicleid !== localSelectedTag.vehicleid
          );
        }
      }
  
      const filtered = updatedData.filter((tram: VehicleStatus) =>
        tram.vehicleid.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
  
      if (
        localSelectedTag &&
        searchTerm &&
        !localSelectedTag.vehicleid
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        setFilteredData(filtered);
      } else {
        setFilteredData(localSelectedTag ? [localSelectedTag, ...filtered] : filtered);
      }
    }
  }, [processedTags, searchTerm, localSelectedTag]);
  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" mb={2}>
          <div style={{position: 'relative', width: '100%'}}>
            <input
              type="text"
              placeholder="Search Tram"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: '100%',
                color: 'black',
                padding: '8px 8px 8px 40px',
                borderRadius: '20px',
                backgroundColor: 'white',
                border: '1px solid #3A4750',
                outline: 'none',
              }}
            />
            <Search
              style={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'gray',
              }}
            />
          </div>
        </Stack>
        <Stack
          spacing={2}
          mr={1}
          sx={{
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          {localSelectedTag && closeFocusTram && (
            <Stack
              key={`${localSelectedTag}`}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              alignItems="center"
            >
              {/* Icon and Chip */}
              <Stack direction="column" alignItems="center">
                <Tram sx={{ fontSize: 50, color: theme.palette.primary.main }} />
                <Chip
                  label="NU"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    ml: 1,
                    borderRadius: theme.shape.borderRadius,
                    
                    fontWeight: 500,
                  }}
                  size="small"
                />
              </Stack>

              {/* Tram Card */}
              <Card
                sx={{
                  width: '100%',
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                
                  border: `2px solid ${theme.palette.primary.main}`,
                }}
                onDoubleClick={() => {
                  onSelectTag(null); // Double click resets the selected tram
                  setLocalSelectedTag(undefined);
                }}
              >
                <CardContent>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                    textAlign={{ xs: 'center', sm: 'left' }}
                  >
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      Spårvagn ID
                    </Typography>

                    <Chip
                      label={localSelectedTag.vehicleid}
                      sx={{
                        backgroundColor: theme.palette.success.main,
                        color: theme.palette.text.primary,
                        fontWeight: 'bold',
                        ml: { sm: 2 },
                        borderRadius: theme.shape.borderRadius,
                        mt: { xs: 1, sm: 0 },
                      }}
                      size="small"
                    />
                    {findTramInfoByNumber(localSelectedTag.vehicleid)?.model && (
                      <Chip
                        label={findTramInfoByNumber(localSelectedTag.vehicleid)?.model}
                        sx={{
                          backgroundColor: theme.palette.success.main,
                          color: theme.palette.text.primary,
                          fontWeight: 'bold',
                          ml: { sm: 2 },
                          borderRadius: theme.shape.borderRadius,
                          mt: { xs: 1, sm: 0 },
                        }}
                        size="small"
                      />
                    )}
                  </Stack>
                  <Divider sx={{ my: 1, backgroundColor: theme.palette.divider }} />

                  {/* Tram Details */}
                  <Stack textAlign={{ xs: 'center', sm: 'left' }}>
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      Starta:{' '}
                      {format(
                        localSelectedTag.items[0]?.detectionTime,
                        'yyyy-MM-dd HH:mm:ss',
                        { locale: sv }
                      )}
                    </Typography>
                  </Stack>

                  {/* Signal Averages */}
                  {functionName  == "Radiokvalitet" &&       <Stack
                    direction={{
                      xs: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                      xl: 'row',
                    }}
                    spacing={1}
                    mt={1}
                  >
                    <Chip
                      label={`Warning ${gettingAverageOfSignals(
                        localSelectedTag.signalAverage.warning,
                        localSelectedTag.count
                      )}%`}
                      sx={{
                        backgroundColor: theme.palette.error.main,
                        color: theme.palette.error.contrastText,
                        borderRadius: theme.shape.borderRadius,
                      }}
                      size="small"
                    />
                    <Chip
                      label={`Alert ${gettingAverageOfSignals(
                        localSelectedTag.signalAverage.alert,
                        localSelectedTag.count
                      )}%`}
                      sx={{
                        backgroundColor: theme.palette.warning.main,
                        color: theme.palette.warning.contrastText,
                        borderRadius: theme.shape.borderRadius,
                      }}
                      size="small"
                    />
                    <Chip
                      label={`Good ${gettingAverageOfSignals(
                        localSelectedTag.signalAverage.good,
                        localSelectedTag.count
                      )}%`}
                      sx={{
                        backgroundColor: theme.palette.success.main,
                        color: theme.palette.success.contrastText,
                        borderRadius: theme.shape.borderRadius,
                      }}
                      size="small"
                    />
                  </Stack>}
             
                </CardContent>
              </Card>
            </Stack>
          )}
          {filteredData &&
            filteredData.map((tram: VehicleStatus, index: number) => (
              <Stack
                key={index + `${tram.data}`}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                alignItems="center"
              >
                {/* Icon and Chip */}
                <Stack direction="column" alignItems="center">
                  <Tram sx={{ fontSize: 50, color: theme.palette.primary.main }} />
                  <Chip
                    label="NU"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      ml: 1,
                      borderRadius: theme.shape.borderRadius,
                      fontWeight: 500,
                    }}
                    size="small"
                  />
                </Stack>

                {/* Tram Card */}
                <Card
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.background.paper,
                    border: `2px solid ${theme.palette.primary.main}`,
                  }}
                  onClick={() => {
                    onSelectTag(tram);
                    setLocalSelectedTag(tram);
                  }}
                
                >
                  <CardContent>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      justifyContent="space-between"
                      textAlign={{ xs: 'center', sm: 'left' }}
                    >
                      <Typography sx={{ color: theme.palette.text.primary }}>
                        Spårvagn ID
                      </Typography>

                      <Chip
                        label={tram.vehicleid}
                        sx={{
                          backgroundColor: theme.palette.success.main,
                          color: theme.palette.text.primary,
                          fontWeight: 'bold',
                          ml: { sm: 2 },
                          borderRadius: theme.shape.borderRadius,
                          mt: { xs: 1, sm: 0 },
                        }}
                        size="small"
                      />
                      {findTramInfoByNumber(tram.vehicleid)?.model && (
                        <Chip
                          label={findTramInfoByNumber(tram.vehicleid)?.model}
                          sx={{
                            backgroundColor: theme.palette.success.main,
                            color: theme.palette.text.primary,
                            fontWeight: 'bold',
                            ml: { sm: 2 },
                            borderRadius: theme.shape.borderRadius,
                            mt: { xs: 1, sm: 0 },
                          }}
                          size="small"
                        />
                      )}
                    </Stack>
                    <Divider sx={{ my: 1, backgroundColor: theme.palette.divider }} />

                    {/* Tram Details */}
                    <Stack textAlign={{ xs: 'center', sm: 'left' }}>
                      <Typography sx={{ color: theme.palette.text.primary }}>
                        Starta:{' '}
                        {format(
                          tram.items[0]?.detectionTime,
                          'yyyy-MM-dd HH:mm:ss',
                          { locale: sv }
                        )}
                      </Typography>
                    </Stack>

                    {/* Signal Averages */}
                    {functionName  == "Radiokvalitet" &&   <Stack
                      direction={{
                        xs: 'column',
                        sm: 'column',
                        md: 'column',
                        lg: 'row',
                        xl: 'row',
                      }}
                      spacing={1}
                      mt={1}
                    >
                      <Chip
                        label={`Warning ${gettingAverageOfSignals(
                          tram.signalAverage.warning,
                          tram.count
                        )}%`}
                        sx={{
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.error.contrastText,
                          borderRadius: theme.shape.borderRadius,
                        }}
                        size="small"
                      />
                      <Chip
                        label={`Alert ${gettingAverageOfSignals(
                          tram.signalAverage.alert,
                          tram.count
                        )}%`}
                        sx={{
                          backgroundColor: theme.palette.warning.main,
                          color: theme.palette.warning.contrastText,
                          borderRadius: theme.shape.borderRadius,
                        }}
                        size="small"
                      />
                      <Chip
                        label={`Good ${gettingAverageOfSignals(
                          tram.signalAverage.good,
                          tram.count
                        )}%`}
                        sx={{
                          backgroundColor: theme.palette.success.main,
                          color: theme.palette.success.contrastText,
                          borderRadius: theme.shape.borderRadius,
                        }}
                        size="small"
                      />
                    </Stack> }
                   
                  </CardContent>
                </Card>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </>
  );
};

export default SideBarTrainCard;
