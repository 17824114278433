import React, { useEffect, useRef, useState } from 'react';
import mapboxgl, { Map, LngLatLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { sv } from 'date-fns/locale';
import { Signal, Feature, DetectorData } from './types/index.ts';
import { throwUnlessDefined } from '../../utils/functional/functionalUtils.ts';
import { AppSettings } from '../../config/appConfigs/appSettings.ts';
import trainMarker from 'assets/images/softprio/trainPointer.png'; // Adjust the path as necessary
import { useGeoJSONLoader } from './hooks/useGeoJSONLoader.ts';
import geoJsonSoftPrio from './tempFile/softPrioTrack.geojson';
import updatedDetectors from './tempFile/updatedDetectors.geojson';
import stations from './tempFile/stations.geojson';
import trackIsolators from './tempFile/track-section-isolators.geojson';
import useFetchTags from './hooks/useFetchTags.ts';
import directionArrow from './tempFile/directionArrow.png';
import useFetchRssiValue from './hooks/useFetchRssiValue.ts';
import useFetchedLoopTags from './hooks/useFetchedLoopTags.ts';
import { useTheme } from '@mui/styles';
import bbox from '@turf/bbox';
import { format } from 'date-fns';
import tramIcon from './tempFile/tram.png';
import { findTramInfoByNumber } from './utils/findTramInformation.tsx';
import { TagData, VehicleStatus, TagDataInstance } from './types/index.ts';
import useDetectorsWithTags from "./hooks/useFetchAllSpecificTags.ts"
import { point, lineString } from '@turf/helpers';
import pointToLineDistance from '@turf/point-to-line-distance';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// import nearestPointOnLine from '@turf/nearest-point-on-line';

// import {distance} from '@turf/distance';

// import TrainLoading from './components/Loading/TrainLoading.tsx';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Stack,
  FormControlLabel, 
  Typography, 
  Checkbox,
  Slider, 
  CircularProgress,
} from '@mui/material';

interface DataItem {
  tagId: number;
  timestamp: number;
}
interface Graph {
  previousMap: { [key: number]: Set<number> }; // Object to map tagId -> Set of previous tagIds
  nextMap: { [key: number]: Set<number> }; // Object to map tagId -> Set of next tagIds
}
// Define the interfaces for data types
interface DetectionEventData {
  old_vehicle_id: number;
  time_unix: number;
  detectorId: number;
  tag_id: number;
  udp_packets?: { tags: number[] };
}
interface Detection {
  eventData: DetectionEventData;
  geoPosition: [number, number];
}
interface GeoJsonFeature {
  type: 'Feature';
  geometry: {
    type: 'LineString' | 'Point';
    coordinates: [number, number][] | [number, number];
  };
  properties: {
    tagIds?: number[];
    detectorIds?: number[];
    associatedTags?: number[];
    tagId?: number;
    detectorId?: number;
  };
}

interface GeoJsonTrack {
  type: 'FeatureCollection';

  features: GeoJsonFeature[];
}

interface TrainTracksData {
  tracks: DetectionPoint[][];

  tagSequences: TagSequence[][];
}

interface MissingTags {
  track: DetectionPoint[];

  missingTags: number[];
}

interface DetectionPoint {
  time: number;

  position: [number, number];

  detectorId: number;

  tagId: number;

  associatedTags: number[];
}

interface TagSequence {
  tagId: number;

  associatedTags: number[];
}

// type MarkerMap = {[key: number]: mapboxgl.Marker};
type MarkerMap = {
  [vehicleId: number]: {
    marker: mapboxgl.Marker; // The Mapbox marker instance
    timestamp: number; // The timestamp indicating the last update
  };
};
function addGlobalStyles() {
  if (!document.getElementById('custom-marker-styles')) {
    const style = document.createElement('style');
    style.id = 'custom-marker-styles';
    style.innerHTML = `
      .marker-pin {
        position:relative;
        width: 5em;
        height: 5em;
        top: -2.5em !important;
        transform: rotate(0deg);
      }
      .marker-pin text {
        font-size: 9em !important;
        dominant-baseline: middle;
        text-anchor: middle;
      }
    `;
    document.head.appendChild(style);
  }
}
function getSignalColor(signalValue: number): string {
  if (signalValue > -65) {
    // above -65
    return '#379C54';
  } else if (signalValue <= -65 && signalValue >= -75) {
    // between -65 and -75
    return '#CBA70E';
  } else if (signalValue < -75 && signalValue >= -85) {
    // between -75 and -85
    return '#fab157';
  } else if (signalValue < -85 && signalValue >= -95) {
    // between -85 and -95
    return '#FA5760';
  } else {
    // below -95
    return ''; // Default case, you can set this to any color or handle differently
  }
}
function stripPrefix(value: string): string {
  const valueStr = value.toString(); // Convert the number to a string
  if (valueStr.startsWith('10')) {
    // Check if it starts with '10'
    return valueStr.slice(2); // Remove the first two characters and convert back to number
  }
  return value; // If it doesn't start with '10', return the original value
}
type TagsPositions = { lng: number; lat: number; id: number }[];

interface MapComponentProps {
  signals: Signal[];
  directionPoints: any[]; // Assuming it's a collection of points
  selectedTag: Feature | null;
  selectedCDC: string;
  functionName: string;
  onAddDetector: (data: DetectorData) => void;
  fetchedTags: TagData[] | undefined;
  singleProcessedTag: VehicleStatus | undefined;
}
interface DirectionPoint {
  type: 'FeatureCollection';
  features: Array<{
    type: 'Feature';
    geometry: {
      type: 'Point';
      coordinates: number[];
    };
    properties: {
      [key: string]: any;
    };
  }>;
}
const { mapboxStyle } = AppSettings;

const MAPBOX_TOKEN_DARK_LIGHT = throwUnlessDefined(process.env.REACT_MAPBOX_TOKEN_FOR_DARK_LIGHT);
const MAPBOX_TOKEN_LIGHT = throwUnlessDefined(process.env.REACT_MAPBOX_TOKEN);
const getMarkerSVGForTramMarker = (vehicleId: string, color: string): string => {
  return `
  <svg class="marker-pin" fill="${color}" height="50px" width="50px" viewBox="0 0 511.974 511.974">
                <g>
                  <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="#0047AB">443</text>
                  <path d="M255.987,0C161.882,0,85.321,76.553,85.321,170.641c0,45.901,14.003,73.924,33.391,112.708
                    c3.012,6.025,6.17,12.331,9.438,19.038c47.753,98.065,120.055,204.783,120.781,205.85c1.587,2.338,4.233,3.738,7.057,3.738
                    s5.47-1.399,7.057-3.738c0.725-1.067,73.028-107.785,120.781-205.85c3.268-6.707,6.426-13.013,9.438-19.038
                    c19.388-38.784,33.391-66.807,33.391-112.708C426.654,76.553,350.093,0,255.987,0z M255.987,255.974
                    c-47.053,0-85.333-38.281-85.333-85.333s38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333
                    S303.04,255.974,255.987,255.974z"/>
                </g>
                <g>
                <circle cx="50%" cy="32%" r="150" fill="white" />
                 <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${color}">${stripPrefix(vehicleId)}</text>
                </g>
              </svg>;
  `;
};

const addTrackOutlineLayer = (map: Map, geojsonData: any) => {
  map.addSource('track-outline', {
    type: 'geojson',
    data: geojsonData,
  });

  map.addLayer({
    id: 'track-outline-layer',
    type: 'line',
    source: 'track-outline',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#008000', // Change to desired color
      'line-width': 4,
    },
  });

  const geojsonBounds = bbox(geojsonData);
  map.fitBounds(geojsonBounds as [number, number, number, number]);
};

const addLineLayer = (map: Map) => {
  map.addSource('line-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'line-layer',
    type: 'line',
    source: 'line-source',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 4,
    },
  });
};

const addCustomMarkersLayer = (map: Map) => {
  map.addSource('custom-markers', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'custom-markers',
    type: 'symbol',
    source: 'custom-markers',
    layout: {
      'icon-image': 'custom-marker', // Reference to the image in the sprite
      'icon-size': 1.0, // Adjust the size as needed
      'text-field': ['get', 'title'],
      'text-offset': [0, 1.25],
      'text-anchor': 'top',
    },
    paint: {
      'text-color': '#000000', // Adjust text color if necessary
    },
  });
};

const addBadRssiValueMarkersLayer = (map: Map, geojson: any) => {
  map.addSource('average-value-rssi-markers-data', {
    type: 'geojson',
    data: geojson,
  });
  map.addLayer({
    id: 'average-value-rssi-markers-layer',
    type: 'symbol',
    source: 'average-value-rssi-markers-data',
    layout: {
      'icon-size': 1.0, // Adjust the size as needed
      'text-field': ['get', 'title'],
      'text-offset': [0, 1.25],
      'text-anchor': 'top',
    },
    paint: {
      'text-color': '#000000', // Adjust text color if necessary
    },
  });
};

const addTrackSectionIsolatorLayer = (map: Map, isolatorData: any) => {
  map.addSource('track-section-isolators', {
    type: 'geojson',
    data: isolatorData,
  });

  map.addLayer({
    id: 'track-section-isolators',
    type: 'line',
    source: 'track-section-isolators',
    filter: ['==', '$type', 'LineString'],
    paint: {
      'line-color': [
        'match',
        ['get', 'Color'],
        'red',
        '#E14E56',
        'green',
        '#3EB05E',
        '#E14E56',
      ],
      'line-width': 3,
    },
  });
};

const addStationsLayer = (map: Map, stationsData: any) => {
  map.addSource('stations-layer', {
    type: 'geojson',
    data: stationsData,
  });

  map.addLayer({
    id: 'stations-layer',
    type: 'symbol',
    source: 'stations-layer',
    layout: {
      'icon-image': 'station-icon-red',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.2,
        14,
        0.17,
        14.1,
        0.19,
        15,
        0.24,
        20,
        0.5,
      ],
      'text-field': '{id}',
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        5,
        14,
        ['*', 3, 3],
        17,
        ['*', 3, 4],
        19,
        ['*', 4, 5],
        20,
        ['*', 5, 6],
      ],
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Regular'],
      'text-offset': [0, 0],
      'text-allow-overlap': true,
      'text-anchor': 'center',
      'text-justify': 'center',
      'text-rotation-alignment': 'auto',
    },
    paint: {
      'text-color': '#ffffff',
    },
  });
};
const addLayerForSignals = (map: Map, directionPoints: any) => {
  addGlobalStyles(); // Ensure styles are added only once
  const colors: string[] = ['#FA5760', '#379C54', '#CBA70E'];
  map.addSource('signal-points', {
    type: 'geojson',
    data: directionPoints,
    cluster: true,
    clusterRadius: 50,
    clusterMaxZoom: 14,
  });

  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: 'signal-points',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': '#51bbd6',
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    },
  });

  // Check if the cluster-count layer exists before adding it
  if (!map.getLayer('cluster-count')) {
    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'signal-points',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    });
  }

  const markers: { [key: string]: mapboxgl.Marker } = {};

  function updateMarkers() {
    const newMarkers: { [key: string]: mapboxgl.Marker } = {};
    const features = map.querySourceFeatures('signal-points', {
      filter: ['!', ['has', 'point_count']],
    });

    features.forEach((feature) => {
      const coords = feature.geometry.coordinates as [number, number];
      const props = feature.properties as any;
      const randomIndex = Math.floor(Math.random() * colors.length);
      const randomColor = colors[randomIndex];
      const id = props.tagID;

      if (!markers[id]) {
        const el = createCustomHTMLMarker(props, randomColor);
        const marker = new mapboxgl.Marker({ element: el }).setLngLat(coords).addTo(map);
        markers[id] = marker;
      }
      newMarkers[id] = markers[id];
    });

    for (const id in markers) {
      if (!newMarkers[id]) {
        markers[id].remove();
        delete markers[id];
      }
    }
  }

  function createCustomHTMLMarker(props: any, randomColor: string) {
    const markersContainer = document.createElement('div');
    markersContainer.style.display = 'block';
    const markerPinSvg = `
      <svg class="marker-pin" fill="${randomColor}" height="50px" width="50px" viewBox="0 0 511.974 511.974">
        <g>

          <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${randomColor}">-69</text>
          <path d="M255.987,0C161.882,0,85.321,76.553,85.321,170.641c0,45.901,14.003,73.924,33.391,112.708
            c3.012,6.025,6.17,12.331,9.438,19.038c47.753,98.065,120.055,204.783,120.781,205.85c1.587,2.338,4.233,3.738,7.057,3.738
            s5.47-1.399,7.057-3.738c0.725-1.067,73.028-107.785,120.781-205.85c3.268-6.707,6.426-13.013,9.438-19.038
            c19.388-38.784,33.391-66.807,33.391-112.708C426.654,76.553,350.093,0,255.987,0z M255.987,255.974
            c-47.053,0-85.333-38.281-85.333-85.333s38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333
            S303.04,255.974,255.987,255.974z"/>
        </g>
        <g>
        <circle cx="50%" cy="32%" r="150" fill="white" />
         <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${randomColor}">-69</text>
        </g>
      </svg>`;

    markersContainer.innerHTML = markerPinSvg;

    return markersContainer;
  }

  map.on('move', updateMarkers);
  map.on('zoom', updateMarkers);
  map.on('data', updateMarkers);

  updateMarkers();
};

const clusteredLayer = (map: Map, directionPoints: DirectionPoint): void => {
  if (!map.hasImage('direction-icon')) {
    map.loadImage(directionArrow, (error, image) => {
      if (error) {
        console.error('Error loading image:', error);
        return;
      }
      if (!image) {
        console.error('Image is undefined.');
        return;
      }
      map.addImage('direction-icon', image);

      map.addSource('clusteredpoints', {
        type: 'geojson',
        data: directionPoints,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'clusteredpoints',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'clusteredpoints',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'clusteredpoints',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'direction-icon',
          'icon-size': 0.04, // Adjust the size as needed
          // 'icon-rotate': ['get', 'direction'], // Rotation value from the feature properties
          'icon-rotate': ['+', ['get', 'direction'], 180],
          'icon-allow-overlap': true,
          'text-anchor': 'top',
        },
      });

      map.on('click', 'unclustered-point', (e) => {
        const features = e.features;
        if (!features || features.length === 0) return;

        const feature = features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const properties = feature.properties;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `
          <style>
          .popup-container {
            font-family: Arial, sans-serif;
            padding: 10px;
            border-radius: 8px;
          }

          .popup-table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;
          }

          .bold {
            font-weight: bold;
          }

          .green {
            color: green;
          }

          .red {
            color: red;
          }

          .align-right {
            text-align: right;
          }

          .padding-top {
            padding-top: 8px;
          }

          .link {
            color: #0066cc;
          }

          .padding-left {
            padding-left: 5px;
          }
          </style>
          <div class="popup-container">
  <table class="popup-table">
    <tr>
      <td class="bold">Detektor</td>
      <td class="align-right bold green">${properties!.detector}</td>
    </tr>
    <tr>
      <td class="padding-top">TagID</td>
      <td class="align-right">${properties!.tagID}</td>
    </tr>
  </table>
</div>
        `,
          )
          .addTo(map);
      });
    });
  }
};

const convertingGeoJsonForHeatMap = (eventData: any[]): any => {
  const savedTagsWithPosition = localStorage.getItem('tagsWithPositions');
  
  if (!savedTagsWithPosition) {
    return null;
  }

  // Parse the stored tags with their positions
  const parsedTagsWithPosition: { [key: string]: { coordinates: [number, number] } } =
    JSON.parse(savedTagsWithPosition);

  // Create a GeoJSON feature collection
  const geoJsonFeatures = eventData.map((item: any) => {
    const tagId = item.eventData?.tag_id;
    const singleTag = parsedTagsWithPosition[tagId];

    // If the tagId doesn't exist in parsedTagsWithPosition, skip this item
    if (!singleTag) return null;

    const vehicleId = item.eventData?.udp_packets?.fordons_id;
    const rssiValue = item.eventData?.udp_packets?.rssi;
    const resendFlag = item.eventData?.udp_packets?.resend_flag;
    const geoPosition = singleTag.coordinates;

    // Construct the GeoJSON feature
    return {
      type: 'Feature',
      properties: {
        vehicleId,
        tag_id: tagId,
        rssi: rssiValue,
        server_timestamp: item.eventData?.server_timestamp,
        resendFlag,
      },
      geometry: {
        type: 'Point',
        coordinates: geoPosition, // Use the coordinates from the saved position
      },
    };
  }).filter((feature) => feature !== null); // Filter out null features if the tagId doesn't match

  // Return the final GeoJSON structure
  return {
    type: 'FeatureCollection',
    features: geoJsonFeatures,
  };
};

const StatusPage: React.FC<MapComponentProps> = ({
  directionPoints,
  selectedTag,
  functionName,
  singleProcessedTag,
  onAddDetector,
  fetchedTags,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const map = useRef<Map | null>(null);
  const { geojsonData, loading, error } = useGeoJSONLoader({ url: geoJsonSoftPrio });
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const [mapBounds, setMapBounds] = useState<mapboxgl.LngLatBounds | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const [tagsPosition, setTagsPosition] = useState<TagsPositions>([]);
  const [markerRefs, setMarkerRefs] = useState<Record<number, mapboxgl.Marker>>({});
  const { data:rssiValuesForHeatMap, isLoading:loadingForHeatMap, errors:errorsForHeatMap } = useDetectorsWithTags();
  const [markerRefsForRssi, setMarkerRefsRefsForRssi] = useState<
    Record<number, mapboxgl.Marker>
  >({});
  const markerTimestamps: { [key: number]: number } = {};
  const [isPowerView, setIsPowerView] = useState(false);
  const [graph, setGraph] = useState<Graph | null>(null);
  
  // Values for Heat Map 
  const [rssiThreshold, setRssiThreshold] = useState(-60); // Initial threshold for good signal (slider value)
  const [isHeatmap, setIsHeatmap] = useState(true); // State for toggling between heatmap and circles
  const [isResendFlag, setIsResendFlag] = useState(false)
  const [resendCounter, setResendCounter] = useState(0);
  const [filterSummary, setFilterSummary] = useState('')



  const {
    data: rssiAverageData,
    isLoading: rssiAverageLoading,
    error: rssiAverageError,
  } = useFetchRssiValue(true);

  const {
    geojsonData: geojsonDataForDetectors,
    loading: loadingDetectors,
    error: errorDetectors,
  } = useGeoJSONLoader({
    url: updatedDetectors,
  });

  const getFilteredGeoJson = (
    eventData: any[],
    threshold: number,
    isResendFlag: boolean,
    resendCounter: number,
  ) => {
    // If heatmap is disabled, return an empty GeoJSON structure
    // Filter the data based on multiple conditions
    const filteredData = eventData.filter((item) => {
      const udpPackets = item.eventData?.udp_packets;
      return (
        udpPackets &&
        udpPackets.rssi <= threshold && // RSSI filter
        udpPackets.resend_flag === isResendFlag && // Resend Flag filter
        udpPackets.resend_counter === resendCounter // Resend Counter filter
      );
    });
    const filterSummary = `Filters Applied:\n
    - RSSI Threshold ≤ ${threshold}\n
    - Resend Flag: ${isResendFlag ? "True" : "False"}\n
    - Resend Counter = ${resendCounter}\n
    - Heatmap: ${isHeatmap ? "Enabled" : "Disabled"}\n
    - Total Points Displayed: ${filteredData.length}\n`;
  // Convert the filtered data to GeoJSON
  const heatMapGeoJson = convertingGeoJsonForHeatMap(filteredData);

  // Add the filter summary to the result (optional)
  return { heatMapGeoJson, filterSummary };
  };

  // Handle data and GeoJSON conversion once data is loaded
  useEffect(() => {
    if(functionName !== "Värmekarta") {
      if (map.current?.getLayer('eventDataLayer')) {
        map.current.removeLayer('eventDataLayer');
    }
    
    if (map.current?.getLayer('eventDataHeatMap')) {
        map.current.removeLayer('eventDataHeatMap');
    }
    
    return 
    } 
    if (!loadingForHeatMap && rssiValuesForHeatMap && isMapLoaded) {
      const {heatMapGeoJson,filterSummary } = getFilteredGeoJson(rssiValuesForHeatMap, rssiThreshold, isResendFlag, resendCounter); // Filter based on threshold
      setFilterSummary(filterSummary)
      if (heatMapGeoJson && map) {
        // Check if the source already exists
        if (map.current!.getSource('eventDataSourceForHeatMap')) {
          // If the source exists, update it with the new filtered data
          map.current!.getSource('eventDataSourceForHeatMap').setData(heatMapGeoJson);
        } else {
          // If the source doesn't exist, create it
          map.current!.addSource('eventDataSourceForHeatMap', {
            type: 'geojson',
            data: heatMapGeoJson, // Add the converted GeoJSON data here
          });
        }

        // Add the layer for heatmap or circles based on the state
        if (isHeatmap) {
          map.current!.setLayoutProperty('eventDataHeatMap', 'visibility', 'visible');
          map.current!.setLayoutProperty('eventDataLayer', 'visibility', 'none');
          if (!map.current!.getLayer('eventDataHeatMap')) {
            map.current!.addLayer({
              id: 'eventDataHeatMap',
              type: 'heatmap', // Heatmap layer
              source: 'eventDataSourceForHeatMap',
              maxzoom: 15,
              paint: {
                'heatmap-intensity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0,  // Zoom level where heatmap starts
                  1,  // Intensity at lower zoom level
                  15, // Max zoom level
                  3,  // Intensity at max zoom level
                ],
                'heatmap-weight': [
                  'interpolate',
                  ['linear'],
                  ['get', 'rssi'], // Use `rssi` as weight
                  -100,  // Minimum rssi value (bad signal)
                  0,     // Low intensity for bad signal
                  rssiThreshold,   // Dynamic threshold for good signal
                  1,     // High intensity for good signal
                ],
                'heatmap-color': [
                  'interpolate',
                  ['linear'],
                  ['heatmap-density'],
                  0, 'rgba(0, 0, 255, 0)',   // Low intensity (blue, transparent)
                  0.1, 'rgb(0, 255, 255)',   // Medium intensity (cyan)
                  0.3, 'rgb(55, 156, 84)',   // High intensity (green)
                  0.5, 'rgb(255, 217, 82)',  // Higher intensity (yellow)
                  0.7, 'rgb(255, 165, 0)',   // Very high intensity (orange)
                  1, 'rgb(228, 160, 160)',   // Max intensity (red)
                ],
                'heatmap-radius': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0, 20, // Radius for lower zoom level
                  15, 40, // Radius for higher zoom level
                ],
                'heatmap-opacity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  7, 0,  // At zoom level 7, heatmap is invisible
                  8, 0.6, // At zoom level 8, heatmap starts becoming visible
                  15, 1, // Full opacity at zoom level 15
                ],
              },
            });
          }
        } else {
          // Add the circle layer for points
          map.current!.setLayoutProperty('eventDataLayer', 'visibility', 'visible');
          map.current!.setLayoutProperty('eventDataHeatMap', 'visibility', 'none');
          if (!map.current!.getLayer('eventDataLayer')) {
            map.current!.addLayer({
              id: 'eventDataLayer',
              type: 'circle', // Circle layer to represent points
              source: 'eventDataSourceForHeatMap',
              paint: {
                'circle-radius': [
                  'interpolate',
                  ['linear'],
                  ['get', 'rssi'], // Change size based on `rssi`
                  -100,  // Minimum RSSI value
                  5,     // Radius for low RSSI
                  0,     // Maximum RSSI value
                  10,    // Radius for high RSSI
                ],
                'circle-color': [
                  'case',
                  ['<', ['get', 'rssi'], -80], '#F17279', // Red for bad signal (rssi < -80)
                  ['<', ['get', 'rssi'], -60], 'rgb(251, 199, 11)', // Yellow for average signal (-60 < rssi < -80)
                  'rgb(19, 234, 80)', // Green for good signal (rssi > -60)
                ],
                'circle-opacity': 0.8,
              },
            });
          }
        }
      }
    }
  }, [rssiValuesForHeatMap, rssiValuesForHeatMap, isMapLoaded, map, rssiThreshold, isHeatmap, functionName, isResendFlag, resendCounter]);

  // Handle the slider change to adjust the rssi threshold for filtering
  const handleSliderChangeForRssiValueForHeatMap = (e) => {
    const newThreshold = parseFloat(e.target.value);
    setRssiThreshold(newThreshold); // Update the threshold dynamically
  };

  // Handle the toggle change to switch between circle and heatmap
  const handToggleChangeForHeatMap = (e:any) => {
    setIsHeatmap(e.target.checked); // Toggle between heatmap and circles
  };
  const markerRefForTram = useRef<mapboxgl.Marker | null>(null);
  const markerForTramLocation = useRef<mapboxgl.Marker | null>(null);
  const [isInitialize, setIsInitialized] = useState<boolean>(false);
  const [isPrevTag, setIsPrevTag] = useState<Feature | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null);
  const [selectedPoint, setSelectedPoint] = useState<number[][] | null>(null);
  // For Tags With ID's  //
  const [processedIds, setProcessedIds] = useState<string[]>([]);
  const [tramMarkers, setTramMarkers] = useState<MarkerMap>({});
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null); // To store the timeout ID
  const [mapStyle, setMapStyle] = useState(process.env.REACT_MAPBOX_STYLE_LIGHT); // Default light style
  const [tramIdForMissingTags, setTramIdForMissingTags] = useState<string>('');

  const [currentTagId, setCurrentTagId] = useState();
  const [openTrendCard, setOpenTrendCard] = useState(false);
  const [intervalData, setIntervalData] = useState({});
  const [filterTrendData, setFilterTrendData] = useState([]);



  const {
    data: loopTagData,
    // error: loopTagDataError,
    isLoading: loopTagDataLoading,
  } = useFetchedLoopTags(tramIdForMissingTags);
  const {
    data: tagsData,
    errors: tagsError,
    isLoading: isTagsLoading,
  } = useFetchTags(processedIds);

  useEffect(() => {
    // Update Mapbox style when theme changes
    setMapStyle(theme.palette.mode === 'dark' ? process.env.REACT_MAPBOX_STYLE_DARK : process.env.REACT_MAPBOX_STYLE_LIGHT);
  }, [theme.palette.mode]);
  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN_DARK_LIGHT;

    if (
      mapContainer.current &&
      !map.current &&
      geojsonData &&
      directionPoints &&
      geojsonDataForDetectors
    ) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapStyle, // Set initial style based on theme
        center: [0, 0],
        zoom: 15,
      });

      map.current.on("load", () => {
        setIsMapLoaded(true);
        map.current!.on("zoom", () => {
          const zoom = map.current?.getZoom();
          if (zoom !== undefined && zoom >= 15 && zoomLevel < 15) {
            setZoomLevel(zoom);
            const bounds = map.current!.getBounds();
            setMapBounds(bounds);
          }
        });

        if (geojsonData) {
          addTrackOutlineLayer(map.current!, geojsonData);
          addLineLayer(map.current!);
          addCustomMarkersLayer(map.current!);
          clusteredLayer(map.current!, geojsonDataForDetectors);
          setIsInitialized(true);
        }
      });
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [geojsonData, directionPoints, geojsonDataForDetectors, mapStyle]); //  Add mapStyle to dependencies

  // New effect to update map style dynamically when theme changes
  useEffect(() => {
    if (map.current) {
      map.current.setStyle(mapStyle); // 🔥 Change Mapbox style dynamically
    }
  }, [mapStyle]); // Runs whenever the theme (mapStyle) changes
  useEffect(() => {
    addGlobalStyles();
    if (Object.keys(markerRefs).length === 0 || tagsData.length === 0) return;
    tagsPosition.forEach(() => {
      tagsData.forEach((data) => {
        const mainData = data;
        if (mainData) {
          const targetMarker = markerRefs[mainData.eventData.tag_id];

          if (targetMarker) {
            const eventData = mainData.eventData;
            const udpData = eventData.udp_packets;

            const trendsSorted =
              mainData.trends?.trends?.sort((a, b) => {
                return new Date(b.currentInterval) - new Date(a.currentInterval);
              }) || [];

            let trend = 'loading';

            if (trendsSorted.length >= 2) {
              const latestRSSI = Number(trendsSorted[0]?.rssi); // Ensure it's a number
              const oldestRSSI = Number(trendsSorted[trendsSorted.length - 1]?.rssi); // Ensure it's a number

              if (!isNaN(latestRSSI) && !isNaN(oldestRSSI)) {
                trend =
                  latestRSSI > oldestRSSI
                    ? 'up'
                    : latestRSSI < oldestRSSI
                      ? 'down'
                      : 'neutral';
              } else {
                trend = 'error'; // Handle cases where RSSI values are missing or invalid
              }
            }

            // Update marker popup
            const popup = new mapboxgl.Popup().setHTML(
              `
              <style>
              .popup-container {
                font-family: Arial, sans-serif;
                padding: 10px;
                border-radius: 8px;
              }
              .popup-table {
                width: 100%;
                border-spacing: 0;
                border-collapse: collapse;
              }
              .bold {
                font-weight: bold;
              }
              .green {
                color: green;
              }
                       .pointer {

      cursor: pointer;

    }
              .red {
                color: red;
              }
              .align-right {
                text-align: right;
              }
              .padding-top {
                padding-top: 8px;
              }
              .link {
                color: #0066cc;
              }
              .progress-bar-bg {
                background-color: lightgray;
                width: 100px;
                height: 10px;
                border-radius: 5px;
                display: inline-block;
                position: relative;
              }
              .progress-bar {
                background-color: ${getSignalColor(udpData.rssi)};
                width: ${udpData.lqi}%;
                height: 100%;
                border-radius: 5px;
              }

              .padding-left {
                padding-left: 5px;
              }
              </style>
              <div class="popup-container">
      <table class="popup-table">
        <tr>
          <td class="bold">Spårvagn ID</td>
          <td class="align-right bold green">${stripPrefix(udpData.fordons_id.toString())}</td>
        </tr>

             <tr>

          <td class="bold">Trend</td>

       <td class="align-right bold pointer ${trendsSorted.length > 0 && trendsSorted[0].trend === 'down' ? 'red' : 'green'
              } trends-cell">${trendsSorted.length > 0 ? trendsSorted[0].trend : 'loading'}</td> 

        </tr>
         <tr>
          <td class="bold">Vagntyp</td>
          <td class="align-right bold green">${findTramInfoByNumber(stripPrefix(udpData.fordons_id.toString()))?.model}</td>
        </tr>
        
        <tr>
          <td class="padding-top">Detektor</td>
          <td class="align-right">0101/0102</td>
        </tr>
        <tr>
          <td class="padding-top" style="color: ${getSignalColor(udpData.rssi)}">${udpData.rssi} dbm</td>
</tr>
      <tr>
          <td class="padding-top">Datum</td>
          <td class="align-right padding-top">${format(
                udpData.timestamp,
                'yyyy-MM-dd HH:mm:ss',
                { locale: sv },
              )}</td>
        </tr>
        <tr>
          <td class="padding-top">Apparaturum</td>
          <td class="align-right padding-top">${udpData.destination_address}</td>
        </tr>
    
        <tr>
          <td class="padding-top">Buffringsvärde</td>
          <td class="align-right padding-top">${udpData.tag_buffer ? udpData.tag_buffer : 0}</td>
        </tr>
      </table>
    </div>
            `,
            );
            targetMarker.setPopup(popup);
            // Update marker element content
            popup.on('open', () => {
              const trendsCell = document.querySelector('.trends-cell');

              if (trendsCell) {
                trendsCell.addEventListener('click', () =>
                  handleClick(mainData.trends?.intervals),
                );
              }
            });

            function handleClick(interval) {
              if (interval) {
                setIntervalData(interval);
                setFilterTrendData(interval);

                setOpenTrendCard(true);

                // Add your logic to show a chart or perform other actions
              } else {
                console.warn('No trend data available');
              }
            }
            const markerElement = targetMarker.getElement();
            // Clear the entire marker element properly by removing all children
            markerElement.innerHTML = '';
            while (markerElement.firstChild) {
              markerElement.removeChild(markerElement.firstChild);
            }
            markerElement.style.display = 'block';
            const markerPinSvg = `
              <svg class="marker-pin" fill="${getSignalColor(udpData.rssi)}" height="50px" width="50px" viewBox="0 0 511.974 511.974">
                <g>
                  <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${getSignalColor(udpData.rssi)}">${udpData.rssi}</text>
                  <path d="M255.987,0C161.882,0,85.321,76.553,85.321,170.641c0,45.901,14.003,73.924,33.391,112.708
                    c3.012,6.025,6.17,12.331,9.438,19.038c47.753,98.065,120.055,204.783,120.781,205.85c1.587,2.338,4.233,3.738,7.057,3.738
                    s5.47-1.399,7.057-3.738c0.725-1.067,73.028-107.785,120.781-205.85c3.268-6.707,6.426-13.013,9.438-19.038
                    c19.388-38.784,33.391-66.807,33.391-112.708C426.654,76.553,350.093,0,255.987,0z M255.987,255.974
                    c-47.053,0-85.333-38.281-85.333-85.333s38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333
                    S303.04,255.974,255.987,255.974z"/>
                </g>
                <g>
                <circle cx="50%" cy="32%" r="150" fill="white" />
                 <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${getSignalColor(udpData.rssi)}">${udpData.rssi}</text>
                </g>
              </svg>`;
            markerElement.innerHTML = markerPinSvg;
            // Update the markerRefs with the new marker if needed (reuse existing)
          }
        }
      });
    });
  }, [tagsData, markerRefs, tagsPosition]);
  // This is the Zoom level to call the api //
  useEffect(() => {
    if (!isMapLoaded || !mapBounds || functionName !== 'Radiokvalitet') {
      Object.values(markerRefs).forEach((marker) => {
        marker.remove();
      });
      setMarkerRefs({});
      return;
    }

    // Calculate screen bounds for queryRenderedFeatures
    const southwest = mapBounds.getSouthWest();
    const northeast = mapBounds.getNorthEast();
    const swScreenCoords = map.current?.project([southwest.lng, southwest.lat]);
    const neScreenCoords = map.current?.project([northeast.lng, northeast.lat]);

    if (!swScreenCoords || !neScreenCoords) return;

    const screenBounds = [
      [swScreenCoords.x, swScreenCoords.y], // bottom-left corner
      [neScreenCoords.x, neScreenCoords.y], // top-right corner
    ];

    // Query features within the screen bounds
    const features = map.current!.queryRenderedFeatures(screenBounds, {
      layers: ['unclustered-point'],
    });

    if (!features || features.length === 0) return;
    if (features.length <= 20) {
      const getIds: string[] = [];
      const featurePositions = features
        .map((feature) => {
          const geometry = feature.geometry;
          const id = parseInt(feature.properties!.tagID, 16);
          getIds.push(id.toString());

          if (geometry.type === 'Point') {
            const [lng, lat] = geometry.coordinates;
            return { lng, lat, id };
          }
          return null;
        })
        .filter(Boolean);
      setProcessedIds(getIds);
      setTagsPosition(featurePositions);
      const newMarkerRefs: Record<number, mapboxgl.Marker> = {};
      // Create markers and add them to the map
      featurePositions.forEach((marker) => {
        const targetMarker = markerRefs[marker!.id];
        if (targetMarker) {
          const markerElement = targetMarker.getElement();
          if (markerElement) {
            markerElement.innerHTML = ''; // Update text to 'Loaded'
          }
        } else {
          const markerElement = document.createElement('div');
          markerElement.innerHTML = 'laoding..';
          const mapMarker = new mapboxgl.Marker(markerElement)
            .setLngLat([marker!.lng, marker!.lat])
            .addTo(map.current!);
          newMarkerRefs[marker!.id] = mapMarker;
        }
      });

      setMarkerRefs((prevRefs) => ({ ...prevRefs, ...newMarkerRefs }));
    }
  }, [zoomLevel, map, mapBounds, isMapLoaded, functionName]);

  useEffect(() => {
    if (functionName === 'Kraftförsörjning') {
      if (!isPowerView) {
        addStationsLayer(map.current!, stations);
        addTrackSectionIsolatorLayer(map.current!, trackIsolators);
        setIsPowerView(true);
        return;
      }
      map.current.setLayoutProperty('stations-layer', 'visibility', 'visible');
      map.current.setLayoutProperty('track-section-isolators', 'visibility', 'visible');
    } else {
      if (isPowerView) {
        map.current.setLayoutProperty('stations-layer', 'visibility', 'none');
        map.current.setLayoutProperty('track-section-isolators', 'visibility', 'none');
      }
    }
  }, [map, isMapLoaded, functionName]);

  useEffect(() => {
    if (isInitialize) {
      if (selectedTag) {
        setIsPrevTag(selectedTag);
        // const popup = new mapboxgl.Popup()
        //   .setLngLat(selectedTag.geometry.coordinates)
        //   .setHTML(`<h3>Detector:${selectedTag.properties.detector}</h3>`)
        //   .addTo(map.current!);

        map.current!.flyTo({
          center: selectedTag.geometry.coordinates as LngLatLike,
          zoom: 20,
          essential: true, // Ensures the animation is essential (required for smooth animation)
          speed: 1.5, // Adjust animation speed as needed
          curve: 1.5, // Adjust animation curve as needed
        });
      } else if (isPrevTag) {
        map.current?.flyTo({
          center: isPrevTag.geometry.coordinates as LngLatLike,
          zoom: 12,
          essential: true, // Ensures the animation is essential (required for smooth animation)
          speed: 1.5, // Adjust animation speed as needed
          curve: 1.5, // Adjust animation curve as needed
        });
        setIsPrevTag(null);
      }
    }
  }, [selectedTag, isInitialize]);

  useEffect(() => {
    if (map.current && functionName === 'Live Status') {
      if (map.current?.getLayer('clusteredpoints')) {
        map.current.setLayoutProperty('clusteredpoints', 'visibility', 'none');
        map.current.removeLayer('clusteredpoints');
      }

      const savedTagsWithPosition = localStorage.getItem('tagsWithPositions');
      if (!savedTagsWithPosition) {
        return;
      }

      const parsedTagsWithPosition: { [key: string]: { coordinates: [number, number] } } =
        JSON.parse(savedTagsWithPosition);
      const newVehicleIds = new Set<number>();
      const currentTime = Date.now();

      fetchedTags!.data.forEach((item: any) => {
        const vehicleId = item.eventData?.udp_packets?.fordons_id;
        if (!vehicleId) return;
        newVehicleIds.add(vehicleId);

        const tagId = item.eventData.tag_id;
        const singleTag = parsedTagsWithPosition[tagId];
        if (!singleTag) return;

        const geoPosition = singleTag.coordinates;

        setTramMarkers((prevMarkers) => {
          const vehicleIdFromSingleProcessedTag = `10${singleProcessedTag?.vehicleid}`;
          const isSelected = vehicleIdFromSingleProcessedTag === `${vehicleId}`;

          const updatedMarkers = { ...prevMarkers };

          if (updatedMarkers[vehicleId]) {
            // Update marker position
            updatedMarkers[vehicleId].marker.setLngLat(geoPosition);

            // Update marker color
            const markerElement = updatedMarkers[vehicleId].marker.getElement();
            markerElement.innerHTML = getMarkerSVGForTramMarker(
              stripPrefix(vehicleId),
              isSelected ? '#B29739' : '#0047AB',
            );
          } else {
            // Create a new marker with the appropriate color
            const svgIcon = getMarkerSVGForTramMarker(
              stripPrefix(vehicleId),
              isSelected ? '#B29739' : '#0047AB',
            );
            const createMarkerElement = (svg: string) => {
              const markerElement = document.createElement('div');
              markerElement.style.display = 'block';
              markerElement.innerHTML = svg;
              return markerElement;
            };

            const newMarker = new mapboxgl.Marker(createMarkerElement(svgIcon))
              .setLngLat(geoPosition)
              .addTo(map.current!);

            const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: false }).setText(
              `Vehicle ID: ${vehicleId}`,
            );
            newMarker.setPopup(popup);

            updatedMarkers[vehicleId] = {
              marker: newMarker,
              timestamp: currentTime,
            };
          }

          return updatedMarkers;
        });
      });

      setTramMarkers((prevMarkers) => {
        const updatedMarkers = { ...prevMarkers };
        Object.keys(prevMarkers).forEach((id) => {
          const vehicleIdNum = parseInt(id);
          if (
            !newVehicleIds.has(vehicleIdNum) &&
            currentTime - prevMarkers[vehicleIdNum].timestamp > 300000
          ) {
            prevMarkers[vehicleIdNum].marker.remove();
            delete updatedMarkers[vehicleIdNum];
          }
        });

        return updatedMarkers;
      });
    } else {
      setTramMarkers((prevMarkers) => {
        Object.values(prevMarkers).forEach(({ marker }) => marker.remove());
        return {};
      });
    }
  }, [map, functionName, fetchedTags, geojsonDataForDetectors, singleProcessedTag]);
  useEffect(() => {
    if (
      map.current &&
      (functionName === 'Detektorer' || functionName === 'Kraftförsörjning')
    ) {
      // Add the layer and source when the condition is met
      // addLayerForSignals(map.current, geojsonDataForDetectors);  //
      map.current.setLayoutProperty('clusters', 'visibility', 'visible');
      map.current.setLayoutProperty('cluster-count', 'visibility', 'visible');
    } else {
      if (map.current?.getLayer('clusters')) {
        map.current.setLayoutProperty('clusters', 'visibility', 'none');
      }
      if (map.current?.getLayer('cluster-count')) {
        map.current.setLayoutProperty('cluster-count', 'visibility', 'none');
      }
    }
    // Cleanup function to remove the layer and source when dependencies change or component unmounts
    return () => { };
  }, [functionName, map, geojsonDataForDetectors]);

  useEffect(() => {
    if (map.current && functionName === 'missingTag') {
      if (map.current.getLayer('clusteredpoints')) {
        map.current.setLayoutProperty('clusteredpoints', 'visibility', 'none');
        map.current.removeLayer('clusteredpoints');
      }
      const savedTagsWithPosition = localStorage.getItem('tagsWithPosition');
      if (!savedTagsWithPosition) return;
      const parsedTagsWithPosition: { [key: string]: { coordinates: [number, number] } } =
        JSON.parse(savedTagsWithPosition);

      // Track currently displayed marker to clean up if selectedTramId changes

      const currentSelectedTramId = tramIdForMissingTags;

      // If selectedTramId is set, filter and process data for this tram only

      if (currentSelectedTramId) {
        const targetTramData = fetchedTags?.data.filter((item: any) => {
          const vehicleId = item.eventData?.udp_packets?.fordons_id;

          return vehicleId == currentSelectedTramId;
        });

        if (targetTramData && targetTramData.length > 0) {
          const tagId = targetTramData[0].eventData.tag_id;
          setCurrentTagId(tagId);
          const singleTag = parsedTagsWithPosition[tagId];
          if (singleTag) {
            const geoPosition = singleTag.coordinates;
            setTramMarkers((prevMarkers) => {
              // Remove markers for previously selected tram

              Object.keys(prevMarkers).forEach((vehicleId) => {
                if (parseInt(vehicleId) !== currentSelectedTramId) {
                  prevMarkers[parseInt(vehicleId)].remove();

                  delete prevMarkers[vehicleId];
                }
              });

              // Update or create the marker for the selected tram

              if (prevMarkers[currentSelectedTramId]) {
                prevMarkers[currentSelectedTramId].setLngLat(geoPosition);
              } else {
                const markerPinSvg = `
                <svg class="marker-pin" fill="#0047AB" height="50px" width="50px" viewBox="0 0 511.974 511.974">
                  <g>
                    <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="#0047AB">$</text>
                    <path d="M255.987,0C161.882,0,85.321,76.553,85.321,170.641c0,45.901,14.003,73.924,33.391,112.708
                      c3.012,6.025,6.17,12.331,9.438,19.038c47.753,98.065,120.055,204.783,120.781,205.85c1.587,2.338,4.233,3.738,7.057,3.738
                      s5.47-1.399,7.057-3.738c0.725-1.067,73.028-107.785,120.781-205.85c3.268-6.707,6.426-13.013,9.438-19.038
                      c19.388-38.784,33.391-66.807,33.391-112.708C426.654,76.553,350.093,0,255.987,0z M255.987,255.974
                      c-47.053,0-85.333-38.281-85.333-85.333s38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333
                      S303.04,255.974,255.987,255.974z"/>
                  </g>
                  <g>
                  <circle cx="50%" cy="32%" r="150" fill="white" />
                   <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="#0047AB">${stripPrefix(currentSelectedTramId)}</text>
                  </g>
                </svg>`;
                const createMarkerElement = (svg: string) => {
                  const markerElement = document.createElement('div');

                  markerElement.style.display = 'block';

                  markerElement.innerHTML = svg;

                  return markerElement;
                };

                const newMarker = new mapboxgl.Marker(createMarkerElement(markerPinSvg))

                  .setLngLat(geoPosition)

                  .addTo(map.current!);

                return { [currentSelectedTramId]: newMarker };
              }

              return prevMarkers;
            });
          }
        }
      } else {
        // If no selectedTramId is set, clean up all markers

        setTramMarkers((prevMarkers) => {
          Object.keys(prevMarkers).forEach((vehicleId) => {
            prevMarkers[parseInt(vehicleId)].remove();
          });

          return {};
        });
      }
    }
  }, [map, functionName, fetchedTags, geojsonDataForDetectors, tramIdForMissingTags]);

  const processTagsData = (detections: any): any => {
    return detections.map((item) => ({
      time: item.detectionTime,

      tagId: item.eventData.tag_id,

      associatedTags: item.eventData.udp_packets.tags,

      vehicleId: item.eventData.udp_packets.fordons_id,
    }));
  };

  // Function to build the graph from the dataset

  const buildGraph = (dataset: DataItem[]): Graph => {
    dataset.sort((a, b) => a.timestamp - b.timestamp); // Sort by timestamp

    const previousMap: { [key: number]: Set<number> } = {}; // Maps tagId -> Set of previous tagIds

    const nextMap: { [key: number]: Set<number> } = {}; // Maps tagId -> Set of next tagIds

    // Build the graph by connecting previous and next tags

    for (let i = 0; i < dataset.length - 1; i++) {
      const currentTag = dataset[i].tagId;

      const nextTag = dataset[i + 1].tagId;

      // Populate nextMap

      if (!nextMap[currentTag]) nextMap[currentTag] = new Set<number>();

      nextMap[currentTag].add(nextTag);

      // Populate previousMap

      if (!previousMap[nextTag]) previousMap[nextTag] = new Set<number>();

      previousMap[nextTag].add(currentTag);
    }

    return { previousMap, nextMap };
  };

  // Function to query previous and next tags from the graph

  const queryTag = (graph: Graph, tagId: number) => {
    const { previousMap, nextMap } = graph;

    const previousTags = previousMap[tagId] ? Array.from(previousMap[tagId]) : [];

    const nextTags = nextMap[tagId] ? Array.from(nextMap[tagId]) : [];

    return { previousTags, nextTags };
  };

  useEffect(() => {
    if (
      map.current &&
      functionName === 'missingTag' &&
      !loopTagDataLoading &&
      loopTagData.length > 0
    ) {
      // if (!loopTagData || loopTagData.length === 0) return;
      const processData = processTagsData(loopTagData);
      const newGraph = buildGraph(processData);
      setGraph(newGraph);
      // const processedTracks = processDetections(loopTagData)

      // console.log("we are in Processed Data", processedTracks)
    }
  }, [loopTagData, loopTagDataLoading, map.current, functionName]);

  useEffect(() => {
    // Setting the state to trigger the LoopData hook

    if (
      functionName === 'missingTag' &&
      singleProcessedTag &&
      singleProcessedTag?.vehicleid !== undefined
    ) {
      const vehicleId = `10${singleProcessedTag.vehicleid}`;

      setTramIdForMissingTags(vehicleId);
    }
  }, [functionName, singleProcessedTag, tramMarkers, fetchedTags]);

  useEffect(() => {
    // Cleanup function to remove the layer and source when dependencies change or component unmounts
    return () => {
      if (map.current?.getLayer('track-outline-for-specific-train-layer')) {
        map.current.removeLayer('track-outline-for-specific-train-layer');
      }

      if (map.current?.getSource('track-outline-for-specific-train')) {
        map.current.removeSource('track-outline-for-specific-train');
      }
    };
  }, [functionName, loopTagDataLoading, loopTagData, singleProcessedTag]);
  useEffect(() => {
    // Only proceed if singleProcessedTag is defined and has a vehicle_id
    if (singleProcessedTag?.vehicleid !== undefined) {
      const vehicleId = `10${singleProcessedTag.vehicleid}`; // Get the vehicle ID
      const vehicleMarker = tramMarkers[vehicleId];
      if (vehicleMarker) {
        const position = vehicleMarker.marker.getLngLat();
        // Zoom in on the vehicle
        map.current?.flyTo({
          center: position,
          zoom: 15, // Set your desired zoom level
          essential: true,
        });

        // Clear any existing timer
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        // Set a timer to zoom out after 5 minutes
        timerRef.current = setTimeout(() => { }, 5 * 60 * 1000); // 5 minutes in milliseconds
      }
    } else {
      // If singleProcessedTag is undefined or vehicle_id is not set, do nothing
      // This will allow the markers to be displayed without zooming in
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }

    // Cleanup function to clear the timer when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [singleProcessedTag, tramMarkers]);

  const convertToGeoJson = (backendResponse, detectorsArray) => {
    
    const features = backendResponse
      .map(({ detectorId, averageRssi }) => {
        const matchedDetector = detectorsArray.find((detector) =>
          detector.tags.some((tag) => tag.decimalTagID.toString() === detectorId),
        );

        if (matchedDetector) {
          const tag = matchedDetector.tags.find(
            (tag) => tag.decimalTagID.toString() === detectorId,
          );

          return {
            type: 'Feature',
            properties: {
              detectorId: matchedDetector.detector,
              averageRssi,
              ...matchedDetector.properties,
              tags: matchedDetector.tags,
              badTag: detectorId
            },
            geometry: {
              type: 'Point',
              coordinates: tag.coordinates,
            },
          };
        }

        return null;
      })
      .filter((feature) => feature !== null);

    return {
      type: 'FeatureCollection',
      features,
    };
  };

  useEffect(() => {
    if ( functionName !== 'Underpresterande') {
      Object.values(markerRefs).forEach((marker) => {
        marker.remove();
      });
      Object.values(markerRefsForRssi).forEach((marker) => {
        marker.remove();
      });
      setMarkerRefsRefsForRssi({})
      setMarkerRefs({});
      return;
    }
    const detectorsWithTags = JSON.parse(
      localStorage.getItem('detectorsWithTags') || '[]',
    );
    if (rssiAverageData !== undefined && functionName == 'Underpresterande') {
      addGlobalStyles();

      const geoJsonResult = convertToGeoJson(rssiAverageData.results, detectorsWithTags);
      map.current!.setLayoutProperty('clusters', 'visibility', 'none');
      geoJsonResult.features.forEach((feature: any) => {
        const { coordinates } = feature.geometry;
        const { title, color, detectorId, averageRssi, tags, badTag } = feature.properties;
        const newMarkerRefs: Record<number, mapboxgl.Marker> = {};
        const markerElement = document.createElement('div');
        markerElement.style.display = 'block';
        // Check if listTags is empty or undefined
        const tagsContent =
          tags && tags.length > 0
            ? tags
              .map(
                (tag: string, index: number) => `
    <tr>
  <td class="padding-top">${index + 1}. Tag ID</td>
  <td class="align-right padding-top" style="color: ${badTag == tag.decimalTagID ? 'red' : 'inherit'}">
    ${tag.decimalTagID}
  </td>
</tr>
      `,
              )
              .join('')
            : `
        <tr>
          <td class="padding-top" colspan="2" style="text-align: center; color: gray;">
            No tags available
          </td>
        </tr>
      `;
        // Generate dynamic HTML for the popup, including the tags list
        const popup = new mapboxgl.Popup().setHTML(
          `
      <style>
      .popup-container {
        font-family: Arial, sans-serif;
        padding: 10px;
        border-radius: 8px;
      }
      .popup-table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
      }
      .bold {
        font-weight: bold;
      }
      .green {
        color: green;
      }
      .pointer {
        cursor: pointer;
      }
      .red {
        color: red;
      }
      .align-right {
        text-align: right;
      }
      .padding-top {
        padding-top: 8px;
      }
      .link {
        color: #0066cc;
      }
      .progress-bar-bg {
        background-color: lightgray;
        width: 100px;
        height: 10px;
        border-radius: 5px;
        display: inline-block;
        position: relative;
      }
      .padding-left {
        padding-left: 5px;
      }
      </style>
      <div class="popup-container">
        <table class="popup-table">
          <tr>
            <td class="padding-top bold">Detektor</td>
            <td class="align-right padding-top bold">${detectorId}</td>
          </tr>
          <tr>
            <td class="padding-top bold">Medelvärde</td>
            <td class="padding-top bold red" style="color: ${getSignalColor(averageRssi)}">${averageRssi.toFixed(1)} dBm</td>
          </tr>
          <tr>
            <td colspan="2" class="padding-top bold">Tags</td>
          </tr>
          ${tagsContent}
        </table>
      </div>
      `,
        );

        const markerPinSvg = `
      <svg class="marker-pin" fill="${getSignalColor(averageRssi)}" height="50px" width="50px" viewBox="0 0 511.974 511.974">
        <g>
          <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${getSignalColor(averageRssi)}">${averageRssi.toFixed(1)}</text>
          <path d="M255.987,0C161.882,0,85.321,76.553,85.321,170.641c0,45.901,14.003,73.924,33.391,112.708
            c3.012,6.025,6.17,12.331,9.438,19.038c47.753,98.065,120.055,204.783,120.781,205.85c1.587,2.338,4.233,3.738,7.057,3.738
            s5.47-1.399,7.057-3.738c0.725-1.067,73.028-107.785,120.781-205.85c3.268-6.707,6.426-13.013,9.438-19.038
            c19.388-38.784,33.391-66.807,33.391-112.708C426.654,76.553,350.093,0,255.987,0z M255.987,255.974
            c-47.053,0-85.333-38.281-85.333-85.333s38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333
            S303.04,255.974,255.987,255.974z"/>
        </g>
        <g>
        <circle cx="50%" cy="32%" r="150" fill="white" />
         <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${getSignalColor(averageRssi)}">${averageRssi.toFixed(1)}</text>
        </g>
      </svg>`;

        markerElement.innerHTML = markerPinSvg;
        const mapMarker = new mapboxgl.Marker(markerElement)
          .setLngLat(coordinates)
          .addTo(map.current!);
          if (newMarkerRefs[detectorId]) {
            newMarkerRefs[detectorId].remove(); // Remove old marker if it exists
          }
     
        newMarkerRefs[detectorId] = mapMarker;
        mapMarker.setPopup(popup);
        setMarkerRefsRefsForRssi((prevRefs) => ({ ...prevRefs, ...newMarkerRefs }));
      
      });
      
    }
    
  }, [rssiAverageData, rssiAverageLoading, rssiAverageError, map, functionName]);

  const handleFilter = (range: any) => {
    const now = new Date(); // Current date
    let startDate, endDate;

    switch (range) {
      case 'last2weeks':
        startDate = new Date(now.setDate(now.getDate() - 14)); // Last 14 days
        endDate = new Date(); // Today
        break;
      case 'lastmonth':
        startDate = new Date();
        startDate.setDate(now.getDate() - 28); // 28 days ago
        endDate = new Date(); // Today
        break;
      case 'previous6months':
        startDate = new Date(intervalData[0].intervalStart); // Default to the earliest date in the data
        endDate = new Date(intervalData[intervalData.length - 1].intervalStart); // Default to the latest date in the data
        break;
      default:
        startDate = new Date(intervalData[0].intervalStart); // Default to the earliest date in the data
        endDate = new Date(intervalData[intervalData.length - 1].intervalStart); // Default to the latest date in the data
    }

    const filtered = intervalData.filter((item) => {
      const itemDate = new Date(item.intervalStart);
      return itemDate >= startDate && itemDate <= endDate;
    });

    setFilterTrendData(filtered);
  };

  const getLineColor = (data: any) => {
    if (!data || data.length < 2) return theme.palette.primary.main; // Default color if not enough data

    const first = data[0].averageRssi;
    const last = data[data.length - 1].averageRssi;

    return last > first ? theme.palette.success.main : theme.palette.error.main;
  };

  const handleResendFlagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsResendFlag(event.target.checked);
  };

  const handleResendCounterChange = (event: Event, newValue: number | number[]) => {
    setResendCounter(newValue as number);
  };
  return (
    <div
      ref={mapContainer}
      id="map"
      className="map-container"
      style={{ height: '100vh', width: '100vw' }}
    >
      {
        (functionName === "Värmekarta" && loadingForHeatMap) &&  <Box sx={{ display: 'flex' }}>
        <CircularProgress  color={theme.palette.primary.main}/>
      </Box>
      }
      { (functionName === "Värmekarta" && !loadingForHeatMap) &&   <Box
      sx={{
        position: 'absolute',
        top: 10,
        left: 10,
        zIndex: 999,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={isHeatmap}
            onChange={handToggleChangeForHeatMap}
            sx={{
              color: theme.palette.primary.main,
              '&.Mui-checked': {
                color: theme.palette.primary.dark,
              },
            }}
          />
        }
        label={<Typography variant="h6" style={{ color: theme.palette.secondary.main }}>Show Heatmap</Typography>}
      />

         {/* Resend Flag */}
         <FormControlLabel
        control={
          <Checkbox
            checked={isResendFlag}
            onChange={handleResendFlagChange}
            sx={{
              color: theme.palette.primary.main,
              '&.Mui-checked': { color: theme.palette.primary.dark },
            }}
          />
        }

        label={<Typography variant="h6" style={{ color: theme.palette.secondary.main }}>Resend Flag</Typography>}
      />
      <Box sx={{ mt: 2 }}>
        <Typography sx={{color:theme.palette.secondary.main}}>Signal Strength (rssi) Threshold:</Typography>
        <Slider
          value={rssiThreshold}
          min={-100}
          max={0}
          step={1}
          onChange={handleSliderChangeForRssiValueForHeatMap}
          sx={{
            width: 200,
            color: theme.palette.secondary.main,
          }}
        />
        <Typography sx={{ mt: 1, color:theme.palette.primary.main }}>
          {rssiThreshold}
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography sx={{color:theme.palette.secondary.main}}>Resend Counter:</Typography>
        <Slider
          value={resendCounter}
          min={0}
          max={3}
          step={1}
          onChange={handleResendCounterChange}
          sx={{ width: 200, color: theme.palette.secondary.main, }}
        />
        <Typography  sx={{ mt: 1, color: theme.palette.primary.main }}>
          {resendCounter}
        </Typography>
      </Box>

      <Box sx={{ mt: 2, whiteSpace: 'pre-line' }}>
  <Typography sx={{color:theme.palette.secondary.main}}>{filterSummary}</Typography>
</Box>
    </Box>}

      <Dialog
        open={openTrendCard}
        onClose={() => setOpenTrendCard(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.paper,

            boxShadow: 'none',
          },
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
          Trend Chart
        </DialogTitle>
        <DialogContent>
          {filterTrendData && filterTrendData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filterTrendData}>
                <CartesianGrid strokeDasharray="3 3" />

                <XAxis
                  dataKey={(item) => new Date(item.intervalStart).toLocaleDateString()}
                  tick={{
                    fill: theme.palette.text.primary, // X-axis labels color
                    fontSize: 12,
                  }}
                />

                <YAxis
                  tick={{
                    fill: theme.palette.text.primary, // X-axis labels color
                    fontSize: 12,
                  }}
                />

                <Tooltip />

                <Line
                  type="monotone"
                  dataKey="averageRssi"
                  // stroke={theme.palette.primary.main}
                  stroke={getLineColor(filterTrendData)}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <p>No data available for the chart.</p>
          )}
        </DialogContent>

        <DialogActions>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="20px"
          >
            {/* Left-aligned Stack */}
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={() => handleFilter('last2weeks')}>
                Last 2 Weeks
              </Button>
              <Button variant="outlined" onClick={() => handleFilter('lastmonth')}>
                Last Month
              </Button>
              <Button variant="outlined" onClick={() => handleFilter('previous6months')}>
                Previous 6 Months
              </Button>
            </Stack>

            {/* Right-aligned Stack */}
            <Stack direction="row">
              <Button onClick={() => setOpenTrendCard(false)} color="primary">
                Close
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>

      <style>
        {`
          .custom-marker {
            width: 32px;
            he0
            ight: 32px;
            background-image: url(${tramIcon});
            background-size: cover;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
          .blink {
            animation: blink-animation 1s infinite;
          }
          @keyframes blink-animation {
            0%, 50%, 100% { opacity: 1; }
            25%, 75% { opacity: 0; }
          }
          .direction-marker {
            width: 32px;
            height: 32px;
            background-image: url(${trainMarker});
            background-size: cover;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>
    </div>
  );
};

export default StatusPage;
