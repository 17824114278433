import React from 'react';
import { TextField, Box } from '@mui/material';

interface SearchBarProps {
  onSearch: (value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        fullWidth
        label="Search train, route, etc..."
        variant="outlined"
        onChange={handleChange}
      />
    </Box>
  );
};

export default SearchBar;