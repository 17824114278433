import {simplyFetchFromGraph} from 'appUtils/alertUtils/graphqlQueryUtils.ts';
import {always, chain, equals, find, ifElse, join, keys, map, when, zipWith} from 'ramda';
import {Duration, subMinutes, subMonths, subWeeks} from 'date-fns';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';
import {GraphqlResponseAlertData} from 'types/alerts/alertMapData';
import {AlertDuration} from 'types/alerts/alertDuration';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {findOrThrow, mapObjToValues, stringifyDate} from 'utils/functional/functionalUtils.ts';
import {AlertLevels} from 'types/alerts/alertLevels';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {PeriodEnum} from 'types/alerts/periodEnum.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';
import {capitalize} from '@rescapes/ramda';
import {
  memoizedCriticalAlertLevelKeys,
  memoizedWarningAlertLevelKeys
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewUtils.ts';
import {VehicleCollectionDevice} from 'types/sensors/vehicleCollectionDevice';
import {bool} from 'prop-types';
import {AttributeAlertLevel} from 'types/alerts/attributeAlertLevelEnums.ts';


/**
 * Creates several graphql queries that are sent together to the server.
 * There is an aggregate query for each AlertGaugeByTimePeriod attribute, today, week, month
 * and a query for the geojson data of the individual alerts
 * @param alertTypeConfig
 * @param vehicleCollectionDevice The id of the trainGroup's CDC to query for the given alertTypeConfig,
 * found at vehicleCollectionDevice[alertTypeConfig.alertTypeKey]
 * @param dateIntervalDescription
 * @param dateIntervalDescription.dateInterval We use the duration along with dateInterval.end to make the start and end date
 * If we are query for a TrainRun, we use dateInterval.start and dateInterval.end
 * @param alertGaugeByTimePeriod 'today', 'week', or 'month'
 * @param skipGeospatialQuery Skip the geospatial query for recursive calls on parent AlertTypeConfigs
 */
export async function getSingleTrainGroupFullAlertGraphqlData(
  alertTypeConfig: AlertTypeConfig,
  vehicleCollectionDevice: VehicleCollectionDevice,
  dateIntervalDescription: DateIntervalDescription,
  alertGaugeByTimePeriod: keyof AlertGaugeByTimePeriod,
  skipGeospatialQuery: boolean = false
): Promise<Perhaps<[GraphqlResponseAlertData, Perhaps<GraphqlResponseAlertData>]>> {
  const pointId = vehicleCollectionDevice[alertTypeConfig.alertTypeKey];
  const dateInterval = dateIntervalDescription.dateInterval!;
  const levels: (keyof AlertLevels)[] = keys(alertTypeConfig.alertLevelToAttribute);
  const types: (keyof AlertLevels)[] = keys(
    alertTypeConfig.alertLevelToAttributeForTypeKeys ||
    alertTypeConfig.alertLevelToAttribute
  );

  const {filteredDurations, calculatedDateInterval, fromFunc, windows} =
    getAlertDateIntervals(
      dateIntervalDescription,
      alertGaugeByTimePeriod,
      dateInterval
    );
  const fromDate = calculatedDateInterval.start;
  // Only used for the aggregate.
  const fromForAggregateStringified = stringifyDate(fromDate);
  const toStringified = stringifyDate(dateInterval.end);

  const unit = alertTypeConfig.unit;

  const signalAggregations = chain((duration) => {
    return zipWith(
      (level, type) => {
        // Use the interval of from - chosen duration to from or dateInterval.start if defined
        const [fromDate, toDate] = [fromFunc(duration, dateInterval), dateInterval.end];
        const [fromStr, toStr] = map(stringifyDate, [fromDate, toDate]);
        const seconds = (toDate.getTime() - fromDate.getTime()) / 1000;
        const windowToUse = findOrThrow((win) => {
          return win.value >= seconds;
        }, windows);

        return `${level}_${duration.label}:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStr}"
        to: "${toStr}"
        window: ${windowToUse.label}
      }
    ) {
      count
    }`;
      },
      levels,
      types
    );
  }, filteredDurations);

  // Get the warning and critical keys, either from
  // alertTypeConfig.alertLevelToAttribute or from alertTypeConfig.alertLevelToAttributeForTypeKeys
  // if the latter is defined
  const nonNormalAlertKeys: (keyof AlertLevels)[] = [...memoizedWarningAlertLevelKeys(alertTypeConfig), ...memoizedCriticalAlertLevelKeys(alertTypeConfig)];
  const mappedTypes: (keyof AlertLevels)[] = when(
    () => Boolean(alertTypeConfig.alertLevelToAttributeForTypeKeys),
    (nonNormalAlertKeys: (keyof AlertLevels)[]) => {
      // Reverse alertTypeConfig.alertLevelToAttributeForTypeKeys since it's values
      // match the values of reversedObj[alertTypeConfig.alertLevelToAttribute and we need its keys
      const reversedObj = Object.fromEntries(Object.entries(alertTypeConfig.alertLevelToAttributeForTypeKeys).map(a => a.reverse()));
      return map(
        (key: keyof AlertLevels) => {
          return reversedObj[alertTypeConfig.alertLevelToAttribute[key] as AttributeAlertLevel];
        },
        nonNormalAlertKeys
      );
    },
  )(nonNormalAlertKeys)

  // Create the geospatial query unless query for a parent AlertTypeConfig
  const geospatialQuery = skipGeospatialQuery ? '' : `levelAll: signals(
    where: {
      _OR: [${
    // Limit to the non-normal alerts. We never show normal alerts on the map
    map((key: string) => {
      return `  { type: {_EQ: "${key}" } }`;
    }, mappedTypes)
  }]
   pointId: { _EQ:"${pointId}"}
      _AND: [
        { timestamp: { _GTE: "${fromForAggregateStringified}" } }
        { timestamp: { _LTE: "${toStringified}"} }
      ]
      unit:{_EQ:${unit}}
      data: { numericValue: { _GT: ${alertTypeConfig.levelAllValueGreaterThan} }}
     }

    paginate: { last: 999 }
  ) {
    edges {
      node {
        timestamp
        type
        data {
          rawValue
        }
        metadata
        location {
          lat
          lon
        }
      }
    }
  }`;
  const pointsData = `
  query{
    ${join('\n', signalAggregations)}
    ${geospatialQuery}
}
  `;
  try {
    const {data} = await simplyFetchFromGraph({
      query: pointsData
    });
    // If there is a parent AlertTypeConfig, query it here as well and return the results,
    // since we need them to calculate percents
    const parentGraphqlResponseAlertDataPair = await (alertTypeConfig.parentAlertTypeConfig ?
      getSingleTrainGroupFullAlertGraphqlData(
        alertTypeConfig.parentAlertTypeConfig,
        vehicleCollectionDevice,
        dateIntervalDescription,
        alertGaugeByTimePeriod,
        false
      ) : undefined);

    // Create AlertGraphqlResponseAlertData, backreferencing the alertTypeConfig used for the query
    return [
      clsOrType<GraphqlResponseAlertData>(
        CemitTypename.alertGraphqlResponseAlertData,
        {
          data,
          alertTypeConfig
        }
      ),
      // The parentGraphqlResponseAlertData if alertTypeConfig.parentAlertTypeConfig, else undefined
      parentGraphqlResponseAlertDataPair?.[0]
    ];
  } catch (error) {
    return undefined;
  }
}

/**
 * Creates the query for all alert time periods but with the date interval based on alertTimePeriod
 * If onlyUseChosenAlertTimePeriod is true, only use that time period
 * TODO Clarify
 * @param dateIntervalDescription
 * @param alertTimePeriod
 * @param dateInterval
 * @param onlyUseChosenAlertTimePeriod
 */
export const getAlertDateIntervals = (
  dateIntervalDescription: DateIntervalDescription,
  alertTimePeriod: keyof AlertGaugeByTimePeriod,
  dateInterval: DateInterval,
  onlyUseChosenAlertTimePeriod: boolean = false
) => {
  // These are only used if dateInterval.start is undefined
  // If dateInterval.start is defined, we use that for the duration along with dateInterval.end
  const durations: AlertDuration[] = [
    {
      label: capitalize(PeriodEnum.today),
      key: PeriodEnum.today,
      func: (date) => {
        // Today uses interval.value minutes to get the minutes up to the chosen DateTime
        return dateIntervalDescription.duration
          ? subMinutes(date, dateIntervalDescription.duration)
          : undefined;
      }
    } as AlertDuration,
    {
      label: capitalize(PeriodEnum.week),
      key: PeriodEnum.week,
      func: (date) => {
        // Ignore interval and get the last week up to the chosen DateTime
        return subWeeks(date, 1);
      }
    } as AlertDuration,
    {
      label: capitalize(PeriodEnum.month),
      key: PeriodEnum.month,
      func: (date) => {
        // Ignore interval and get the last month up to the chosen DateTime
        return subMonths(date, 1);
      }
    } as AlertDuration
  ];
  const filteredDurations =
    dateIntervalDescription.label == 'Short' ? [durations[0]] : durations;

  // MINUTE_1 up to 24 hours, HOUR_1 up to 7 days, DAY_1 over that
  const windows = [
    {label: 'MINUTE_1', value: 60 * 60 * 24}, // For up to and including 1 day
    {label: 'HOUR_1', value: 60 * 60 * 24 * 7}, // For up to and including 7 days
    {label: 'DAY_1', value: 60 * 60 * 24 * 365} // For all else
  ];

  const chosenAlertTimePeriod: AlertDuration = findOrThrow<Duration>(
    (filteredDuration: AlertDuration): AlertDuration => {
      return equals(filteredDuration.key, alertTimePeriod);
    },
    filteredDurations
  );

  // If  duration.func(dateInterval.end) is undefined, we have a TrainRun so use dateInterval.start
  const fromFunc = (duration: AlertDuration, dateInterval: DateInterval): Date =>
    duration.func(dateInterval.end) || dateInterval.start;
  const fromDate = fromFunc(chosenAlertTimePeriod, dateInterval);
  const toDate = dateInterval.end;

  return {
    filteredDurations: onlyUseChosenAlertTimePeriod
      ? [chosenAlertTimePeriod]
      : filteredDurations,
    calculatedDateInterval: clsOrType<DateInterval>(CemitTypename.dateInterval, {
      start: fromDate,
      end: toDate
    }),
    fromFunc,
    windows
  };
};
