import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { AppSettings } from '../../config/appConfigs/appSettings';
import { throwUnlessDefined } from '../../utils/functional/functionalUtils';

const { mapboxStyle } = AppSettings;
const MAPBOX_TOKEN = throwUnlessDefined(process.env.REACT_MAPBOX_TOKEN);
mapboxgl.accessToken = MAPBOX_TOKEN;

type Coordinate = [number, number];

const subdivideSegment = (p1, p2, subdivisions) => {
  const segments = [];
  for (let i = 0; i < subdivisions; i++) {
    const t1 = i / subdivisions;
    const t2 = (i + 1) / subdivisions;
    const start = [p1[0] + (p2[0] - p1[0]) * t1, p1[1] + (p2[1] - p1[1]) * t1];
    const end = [p1[0] + (p2[0] - p1[0]) * t2, p1[1] + (p2[1] - p1[1]) * t2];
    segments.push([start, end]);
  }
  return segments;
};

// const createBarPolygonFromEndpoints = (p1, p2, barWidth) => {
//   const center = [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2];
//   const dx = p2[0] - p1[0];
//   const dy = p2[1] - p1[1];
//   const angle = Math.atan2(dy, dx);
//   const halfLength = Math.sqrt(dx * dx + dy * dy) / 2;
//   const halfWidth = barWidth / 2;

//   const rotateAndTranslate = (x, y) => {
//     const rx = x * Math.cos(angle) - y * Math.sin(angle);
//     const ry = x * Math.sin(angle) + y * Math.cos(angle);
//     return [center[0] + rx, center[1] + ry];
//   };

//   return {
//     type: 'Polygon',
//     coordinates: [[
//       rotateAndTranslate(-halfLength, -halfWidth),
//       rotateAndTranslate(-halfLength, halfWidth),
//       rotateAndTranslate(halfLength, halfWidth),
//       rotateAndTranslate(halfLength, -halfWidth),
//       rotateAndTranslate(-halfLength, -halfWidth)
//     ]]
//   };
// };

function createBarPolygonFromEndpoints(start, end, baseWidth) {
  const [x1, y1] = start;
  const [x2, y2] = end;

  const midpoint = [(x1 + x2) / 2, (y1 + y2) / 2]; // Find center of bar
  const taperFactor = 0.2; // Controls the sharpness of the needle tip

  return {
    type: 'Polygon',
    coordinates: [[
      [x1 - baseWidth, y1], // Bottom Left
      [x2 + baseWidth, y2], // Bottom Right
      [midpoint[0], midpoint[1] + baseWidth * taperFactor], // Needle Tip
      [x1 + baseWidth, y1], // Top Left
      [x2 - baseWidth, y2], // Top Right
      [x1 - baseWidth, y1] // Close Polygon
    ]]
  };
}

const SandUsageSubdividedTrackMap = ({ track = [
  [
    -0.6823966708922455,
    51.40378598563581
  ],
  [
    -0.6825807270810174,
    51.40291698070092
  ],
  [
    -0.6827149395991228,
    51.401409867864515
  ],
  [
    -0.6824995036832036,
    51.399167678001646
  ],
  [
    -0.6821696119979492,
    51.39789557030812
  ],
  [
    -0.6818356481691126,
    51.39701554788215
  ],
  [
    -0.6805928253496063,
    51.39374054710538
  ],
  [
    -0.6795637196943858,
    51.39109639410589
  ],
  [
    -0.679443674741151,
    51.3888387890828
  ],
  [
    -0.6805316431830875,
    51.38485359698706
  ],
  [
    -0.6834853216369083,
    51.37745420103073
  ],
  [
    -0.686255863166565,
    51.37026188818169
  ]
], subdivisionsPerSegment = 0, barWidth = 0.0005 }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const selectedSegments = new Set([1, 3, 5,]); // Selected segments for activation visualization
  const barFeatures = track.slice(0, track.length - 1).flatMap((p1, segIndex) => {
    if (!selectedSegments.has(segIndex)) return []; // Only process selected segments
    const p2 = track[segIndex + 1];
    const segmentSubdivisions = Math.floor(Math.random() * (10 - 5 + 1) + 10);
    const subSegments = subdivideSegment(p1, p2, segmentSubdivisions);
    const segmentPosition = segIndex / track.length; // Normalize position (0 to 1)
    // Interpolating activation time to decrease gradually along the track
    const featureType = Math.random() < 0.5 ? "greenFeature" : "yellowFeature";
    return subSegments.map(([subP1, subP2], subIndex) => {
      const activationTime = Math.floor(Math.random() * (40 - 10 + 1) + 10); //
      const flowRate = (Math.random() * (3.0 - 2.5) + 2.5).toFixed(2);
      const totalSandUsed = ((activationTime / 60) * flowRate).toFixed(2);
      const activationRate = Math.floor((activationTime / 40) * 100); // Adjusted for max 40
      const height = (activationRate / 100) * 700;
      // Assign featureType based on conditions
      return {
        type: 'Feature',
        // geometry: createBarPolygonFromEndpoints(subP1, subP2, barWidth * 0.5),
        geometry: createBarPolygonFromEndpoints(subP1, subP2, barWidth * 0.5),
        properties: {
          activationTime,
          flowRate,
          totalSandUsed,
          activationRate,
          height,
          segment: segIndex + 1,
          subdivision: subIndex + 1,
          featureType // Assigns "greenFeature" or "yellowFeature"
        }
      };
    });
  });
  useEffect(() => {
    console.log(barFeatures)
    if (mapContainerRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: mapboxStyle,
        center: track[0],
        zoom: 13.454236564204463,
        pitch: 71.00559385192655,
        bearing: -88.01514642983199,

        antialias: true
      });
      mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      mapRef.current.on("moveend", () => {
        console.log("Camera moved!");
        console.log("Zoom:", mapRef.current.getZoom());
        console.log("Pitch:", mapRef.current.getPitch());
        console.log("Bearing:", mapRef.current.getBearing());
        console.log("Center:", mapRef.current.getCenter());
      });

      mapRef.current.on("zoomend", () => {
        console.log("New Zoom Level:", mapRef.current.getZoom());
      });

      mapRef.current.on('load', () => {

        mapRef.current?.addSource('track', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: { type: 'LineString', coordinates: track }
          }
        });
        mapRef.current?.addLayer({
          id: 'track-line',
          type: 'line',
          source: 'track',
          paint: {
            'line-color': '#888',
            'line-width': 4
          }
        });

        mapRef.current?.addSource('sandUsageBars', {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: barFeatures }
        });


        mapRef.current?.addLayer({
          id: 'sandUsage-bars',
          type: 'fill-extrusion',
          source: 'sandUsageBars',
          paint: {
            'fill-extrusion-color': ['interpolate', ['linear'], ['get', 'activationRate'], 10, '#58111A', 40, '#B31B1B', 70, '#7C0A02'],
            'fill-extrusion-height': ['get', 'height'],
            'fill-extrusion-base': 10,
            'fill-extrusion-opacity': 0.9
          }
        });
        mapRef.current?.addLayer({
          id: 'sandUsage-bars-base',
          type: 'fill-extrusion',
          source: 'sandUsageBars',
          paint: {
            'fill-extrusion-color': [
              'case',
              ['==', ['get', 'featureType'], 'greenFeature'], '#00FF00', // Green Base
              ['==', ['get', 'featureType'], 'yellowFeature'], '#FFFF00', // Yellow Base
              '#FFFFFF' // Default fallback
            ],
            'fill-extrusion-height': 10, // Fixed base height (adjust as needed)
            'fill-extrusion-opacity': 0.9
          }
        });

        mapRef.current?.on('click', 'sandUsage-bars', (e) => {
          const features = mapRef.current?.queryRenderedFeatures(e.point, { layers: ['sandUsage-bars'] });
          if (features && features.length > 0) {
            const feature = features[0].properties;
            new mapboxgl.Popup({ maxWidth: '200%' })
              .setLngLat(e.lngLat)
              .setHTML(
                `<table border="1" style="width:100%; text-align:center;">
                  <tr><th>Segment</th><th>Subdivision</th><th>Activation Time (s)</th><th>Flow Rate (kg/min)</th><th>Total Sand Used (kg)</th><th>Activation Rate (%)</th></tr>
                  <tr><td>${feature.segment}</td><td>${feature.subdivision}</td><td>${feature.activationTime}</td><td>${feature.flowRate}</td><td>${feature.totalSandUsed}</td><td>${feature.activationRate}</td></tr>
                </table>`
              )
              .addTo(mapRef.current!);
          }
        });
      });
    }
    return () => { mapRef.current?.remove(); };
  }, [barFeatures, track]);

  return <div ref={mapContainerRef} style={{ width: '100%', height: '500px' }} />;
};

export default SandUsageSubdividedTrackMap;